import React from "react";

export const ContentRow = ({ title, children }) => {
  return (
    <div className="flexy">
      <div className="SettingsContentTitle">{title}</div>
      <div className="SettingsContentOption">{children}</div>
    </div>
  );
};

export const DefaultCategory = () => {
  return <></>;
};
