import React, { createContext, useContext, useState, useEffect } from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./themes";

const ThemeContext = createContext(undefined);

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a ThemeProvider");
  }
  return context;
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    return localStorage.getItem("theme") || "light";
  });

  const toggleTheme = (newTheme) => {
    if (newTheme) {
      setTheme(newTheme);
      localStorage.setItem("theme", newTheme);
    } else {
      setTheme((prevTheme) => {
        const toggledTheme = prevTheme === "light" ? "dark" : "light";
        localStorage.setItem("theme", toggledTheme);
        return toggledTheme;
      });
    }
  };

  useEffect(() => {
    document.body.className = theme === "light" ? "light-theme" : "dark-theme";
    localStorage.setItem("theme", theme);
  }, [theme]);

  const value = { theme, toggleTheme };

  return (
    <ThemeContext.Provider value={value}>
      <StyledThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
        {children}
      </StyledThemeProvider>
    </ThemeContext.Provider>
  );
};
