import React, { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MuiLink from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/images/teledeckk.png";
import "../../../assets/css/SignUp.css";
import { useAuth } from "../auth-context/AuthContext";

import {
  validateEmail,
  isPhoneValid,
  validatePassword,
  comparePasswords,
  showErrorAlert,
  registerUser,
} from "./SignUp_utils";
import Swal from "sweetalert2";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="/">
        Teledeck
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();
export default function SignUp() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showcode, setShowcode] = useState(false);
  const [organizationCode, setOrganizationCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const handleFirstnameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setEmailError(validateEmail(event.target.value));
  };

  const handlePhoneNumberChange = (phone) => {
    setPhoneNumber(phone);
    setPhoneNumberError(isPhoneValid(phone));
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError(validatePassword(event.target.value));
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setPasswordError(comparePasswords(password, event.target.value));
  };
  const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleToggleCodeVisibility = () => setShowcode(!showcode);

  const handleOrganizationChange = (event) => {
    setOrganizationCode(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    const formData = {
      firstName,
      lastName,
      email,
      phoneNumber,
      organizationCode,
      password,
      confirmPassword,
    };

    try {
      const data = await registerUser(formData);

      if (data.status === "success") {
        setAuth({
          isAuthenticated: data.isVerified === 1 ? true : false,
          isVerified: data.isVerified === 1 ? true : false,
          role: data.role,
          isLoading: false,
          userId: data.userId,
        });

        navigate("/", { replace: true });
        Swal.fire({
          icon: "success",
          title: "Registration Successful",
          text: `Welcome to our platform!`,
        });
      } else {
        console.error("Registration unsuccessful");
        showErrorAlert(
          "Registration failed",
          (data && Array.isArray(data.message)
            ? data.message[0]
            : data.message) || "Registration unsuccessful"
        );
      }
    } catch (error) {
      console.error("An unexpected error occurred during registration:", error);
      showErrorAlert("An unexpected error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Your Logo"
            style={{
              width: "300px",
              marginTop: "20px",
              marginBottom: "60px",
            }}
          />
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={handleFirstnameChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <PhoneInput
                  required
                  fullWidth
                  name="phoneNumber"
                  label="Phone Number"
                  type="number"
                  id="number"
                  autoComplete="number"
                  value={phoneNumber}
                  error={!!phoneNumberError}
                  helperText={phoneNumberError}
                  defaultCountry="lb"
                  inputClassName="form-control"
                  className="phone-input-1"
                  onChange={handlePhoneNumberChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={validateEmail}
                  error={!!emailError}
                  helperText={emailError}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="organization_code"
                  label="Organization Code"
                  name="organization_code"
                  autoComplete="organization code"
                  type={showcode ? "text" : "password"}
                  value={organizationCode}
                  onChange={handleOrganizationChange}
                  required
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleToggleCodeVisibility}>
                          {showcode ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={handlePasswordChange}
                  onBlur={validatePassword}
                  error={!!passwordError}
                  helperText={passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="Cpassword"
                  label="Confirm Password"
                  type={showPassword ? "text" : "password"}
                  id="Cpassword"
                  autoComplete="Cpassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  onBlur={validatePassword}
                  error={!!passwordError}
                  helperText={passwordError}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              disabled={isSubmitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "#6985C0" }}
            >
              {" "}
              {isSubmitting ? "Signing Up..." : "Sign Up"}
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <MuiLink component={Link} to="/SignIn" variant="body2">
                  Already have an account? Sign in
                </MuiLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}
