export const getTrendingTopics = async (
  setTrendingData,
  setLoading,
  language,
  location,
  time
) => {
  setLoading(true);
  try {
    const response = await fetch(
      "https://python.data.teledeck.news/get_trends",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          language,
          location,
          time: parseInt(time, 10),
        }),
      }
    );

    if (response.status === 200) {
      const data = await response.json();
      const transformedData = data.map(([name, count]) => ({
        name,
        count,
      }));
      setTrendingData(transformedData);
    }
  } catch (error) {
    console.error("Failed to fetch trending topics", error);
  } finally {
    setLoading(false);
  }
};
