import {
  deleteAuth,
  getAuth,
  postAuth,
  putAuth,
} from "../../../../global/globalUtils";
import { toast } from "sonner";

export const fetchData = async (
  currentPage,
  itemsPerPage,
  setLoading,
  setFilteredData,
  setCount
) => {
  try {
    const response = await getAuth(
      `/organization_payment/payments?page=${currentPage}&limit=${itemsPerPage}`
    );
    const responseData = await response.json();
    setFilteredData(responseData.payments); // Initially set filtered data to be the same as fetched data
    setCount(responseData.totalPayments);
  } catch (error) {
    console.error("Failed to fetch payments:", error);
    setFilteredData([]); // Clear filtered data if fetch fails
  } finally {
    setLoading(false);
  }
};

// Create a new payment
export const createPay = async (paymentData) => {
  try {
    const response = await postAuth(`/organization_payment/payments`, {
      organization_id: paymentData.organization_id,
      amount: paymentData.amount,
      status: paymentData.status,
      paid_at: paymentData.paid_at,
      created_at: paymentData.created_at,
      due_by: paymentData.due_by,
    });
    await response.json();
    if (response.ok) {
      toast.success("Payment created successfully", { position: "top-center" });
    } else {
      toast.error("Failed to create payment", { position: "top-center" });
    }
  } catch (error) {
    toast.error("Error creating payment", { position: "top-center" });
    console.error("Failed to create payment:", error);
  }
};

// Edit an existing payment
export const editPay = async (paymentId, paymentData) => {
  try {
    const response = await putAuth(
      `/organization_payment/payments/${paymentId}`,
      {
        organization_id: paymentData.organization_id,
        amount: paymentData.amount,
        status: paymentData.status,
        paid_at: paymentData.paid_at,
        created_at: paymentData.created_at,
        due_by: paymentData.due_by,
      }
    );
    await response.json();
    if (response.ok) {
      toast.success("Payment updated successfully", { position: "top-center" });
    } else {
      toast.error("Failed to update payment", { position: "top-center" });
    }
  } catch (error) {
    toast.error("Error updating payment", { position: "top-center" });
    console.error("Failed to update payment:", error);
  }
};

// delete a payment
export const deletePay = async (paymentId) => {
  try {
    const response = await deleteAuth(
      `/organization_payment/payments/${paymentId}`
    );
    await response.json();
    if (response.ok) {
      toast.success("Payment deleted successfully", { position: "top-center" });
    } else {
      toast.error("Failed to delete payment", { position: "top-center" });
    }
  } catch (error) {
    toast.error("Error deleting payment", { position: "top-center" });
    console.error("Failed to delete payment:", error);
  }
};

export const fetchOrgs = async (setOrganizations) => {
  try {
    const response = await getAuth("/organization_payment/all-orgs");
    const responseData = await response.json();
    setOrganizations(responseData.data);
  } catch (error) {
    console.error("Failed to fetch organizations:", error);
  }
};

// Format date for display
export const formatDate = (apiDate) => {
  const date = new Date(apiDate);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return date.toLocaleDateString(undefined, options);
};

// Format date for input type="datetime-local"
export const formatToDatetimeLocal = (dateString) => {
  if (!dateString) return ""; // Handle empty dates
  const date = new Date(dateString);
  const formattedDate = date.toISOString().slice(0, 16); // 'YYYY-MM-DDTHH:MM'
  return formattedDate;
};
