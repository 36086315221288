import React from "react";
import { Chip } from "./ColumnStyles";
import { updateColumnsToAPIChips } from "../column-utils/ColumnUtils";
import { updateColumnInDB } from "../../localdb/DBColumn";

const FilterChips = ({
  column,
  setColumn,
  setForceUpdate,
  updateColumn,
  updateTimestamp,
}) => {
  const handleChipClick = async (chipType) => {
    updateTimestamp();
    const updates = {
      get_text_only: 0,
      get_image: column.get_image,
      get_video: column.get_video,
      get_preview: 0,
    };

    switch (chipType) {
      case "textOnly":
        updates.get_text_only = column.get_text_only ? 0 : 1;
        break;
      case "images":
        updates.get_image = column.get_image ? 0 : 1;
        break;
      case "videos":
        updates.get_video = column.get_video ? 0 : 1;
        break;
      case "preview":
        updates.get_preview = column.get_preview ? 0 : 1;
        break;
      default:
        break;
    }

    if (chipType === "preview" && updates.get_preview === 1) {
      updates.get_text_only = 0;
      updates.get_image = 0;
      updates.get_video = 0;
    } else if (
      (chipType === "images" || chipType === "videos") &&
      (updates.get_image === 1 || updates.get_video === 1)
    ) {
      updates.get_preview = 0;
    } else if (chipType === "textOnly" && updates.get_text_only === 1) {
      updates.get_image = 0;
      updates.get_video = 0;
      updates.get_preview = 0;
    }

    const updatedColumn = {
      ...column,
      ...updates,
    };

    setColumn(updatedColumn);
    setForceUpdate((f) => !f);

    try {
      await updateColumnInDB(column.id, updatedColumn);
    } catch (error) {
      console.error("Failed to update column in DB:", error);
    }

    try {
      await updateColumnsToAPIChips(updatedColumn);
    } catch (error) {
      console.error("Failed to update column in API:", error);
    }

    updateColumn(updatedColumn);
  };

  return (
    <div className="chips-container">
      <Chip
        $active={column.get_text_only === 1}
        onClick={() => handleChipClick("textOnly")}
        className="material-symbols-outlined chip-icon"
        title="Filter By Text Only"
      >
        title
      </Chip>
      <hr className="vertical-line" />

      <Chip
        $active={column.get_preview === 1}
        onClick={() => handleChipClick("preview")}
        className="material-symbols-outlined chip-icon"
        title="Filter By Previews"
      >
        preview
      </Chip>
      <hr className="vertical-line" />

      <Chip
        $active={column.get_image === 1}
        onClick={() => handleChipClick("images")}
        className="material-symbols-outlined chip-icon"
        title="Filter By Images"
      >
        image
      </Chip>

      <Chip
        $active={column.get_video === 1}
        onClick={() => handleChipClick("videos")}
        className="material-symbols-outlined chip-icon"
        title="Filter By Videos"
      >
        videocam
      </Chip>
    </div>
  );
};

export default FilterChips;
