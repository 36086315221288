import React from "react";
import { ScaleLoader } from "react-spinners";

export default function LoadingSpinner() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#f5f5f5",
      }}
    >
      <h1>Teledeck</h1>
      <ScaleLoader color="#000000" />
    </div>
  );
}
