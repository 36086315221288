import { useEffect, useState } from "react";
import { getAuth } from "../../../../global/globalUtils";

const AreaProgressChart = () => {
  const [serviceData, setServiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxCount, setMaxCount] = useState(0); // To store the maximum count

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        // Fetch data from the API
        const response = await getAuth("/admin_user/posts-per-category");
        const data = await response.json();

        if (data.status === "success") {
          // Find the maximum count across all subcategories
          const max = Math.max(
            ...data.data.flatMap((category) =>
              category.subcategories.map((subcategory) => subcategory.count)
            )
          );
          setMaxCount(max); // Set the max count for scaling

          setServiceData(data.data); // Set the data to the fetched categories
        }
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategoryData();
  }, []);

  return (
    <div className="progress-bar">
      <div className="progress-bar-info">
        <h4 className="progress-bar-title">
          Number of Posts by Category During Last 7 Days
        </h4>
      </div>
      {isLoading ? (
        <p>Loading service data...</p>
      ) : (
        <div className="progress-bar-list">
          {serviceData.map((mainCategory) => (
            <div key={mainCategory.id} className="main-category">
              {/* Main Category Title */}
              <h5 className="main-category-title">{mainCategory.category}</h5>

              {/* Subcategories */}
              {mainCategory.subcategories.map((subcategory) => (
                <div className="progress-bar-item" key={subcategory.id}>
                  <div className="bar-item-info">
                    <p className="bar-item-info-name">{subcategory.category}</p>
                    <p className="bar-item-info-value">
                      {subcategory.count} posts
                    </p>
                  </div>
                  <div className="bar-item-full">
                    <div
                      className="bar-item-filled"
                      style={{
                        width: `${(subcategory.count / maxCount) * 100}%`, // Adjust based on max count
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AreaProgressChart;
