import React from "react";
import "../AreaTable.scss";
import Table from "./Table";

const AreaTable = () => {
  return (
    <>
      <section className="content-area-table">
        <h1
          style={{
            textAlign: "center",
            fontSize: "1.5rem",
            marginBottom: "1rem",
          }}
        >
          Channel Requests Table
        </h1>
        <Table />
      </section>
    </>
  );
};

export default AreaTable;
