import AreaCards from "../components/charts/areaCards/AreaCards";
import AreaCharts from "../components/charts/areaCharts/AreaCharts";
const Dashboard = () => {
  return (
    <div className="content-area">
      <AreaCards />
      <AreaCharts />
    </div>
  );
};

export default Dashboard;
