import React from "react";
import { BookmarkProvider } from "../components/side-panel/sidepanel-components/bookmarks/BookmarkContext";
import { SearchProvider } from "../components/side-panel/sidepanel-components/global-search/SearchContext";
import { ThemeProvider } from "../components/themes/ThemeContext";
import GlobalStyle from "../global/GlobalStyle";
import { ToastContainer } from "react-toastify";
import { Toaster } from "sonner";

const Providers = ({ children }) => {
  return (
    <ThemeProvider>
      <BookmarkProvider>
        <SearchProvider>
          {/* GlobalStyle can be considered a global provider for styled-components */}
          <GlobalStyle />
          <ToastContainer limit={2} style={{ zIndex: 10 }} />
          <Toaster richColors position="top-center" />
          {children}
        </SearchProvider>
      </BookmarkProvider>
    </ThemeProvider>
  );
};

export default Providers;
