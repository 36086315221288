import { toast } from "sonner";
import { getAuth, postAuth, putAuth } from "../../../../global/globalUtils";

export const fetchData = async (
  currentPage,
  itemsPerPage,
  setLoading,
  setFilteredData,
  setCount
) => {
  try {
    const response = await getAuth(
      `/organization/?page=${currentPage}&limit=${itemsPerPage}`
    );
    const responseData = await response.json();
    setFilteredData(responseData.data); // Initially set filtered data to be the same as fetched data
    setCount(responseData.total);
  } catch (error) {
    console.error("Failed to fetch users:", error);
    setFilteredData([]); // Clear filtered data if fetch fails
  } finally {
    setLoading(false);
  }
};

export const fetchFilteredData = (
  setLoading,
  filteredData,
  setFilteredData,
  setCount,
  searchTerm,
  fetchData,
  currentPage,
  itemsPerPage
) => {
  if (searchTerm.trim() === "") {
    fetchData(currentPage, itemsPerPage, setLoading, setFilteredData, setCount);
    return;
  }

  setLoading(true);
  try {
    const filtered = filteredData.filter((org) =>
      org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredData(filtered);
    setCount(filtered.length); // Adjust total count for pagination
  } catch (error) {
    console.error("Failed to filter users:", error);
    setFilteredData([]); // Clear the data if there's an error
  } finally {
    setLoading(false);
  }
};

export const getTeamMembers = async (
  id,
  setTeamMembers,
  page,
  rowsPerPage,
  setTotal
) => {
  try {
    const response = await getAuth(
      `/organization/organization-users/?organization=${id}&page=${page}&limit=${rowsPerPage}`
    );
    const responseData = await response.json();
    setTeamMembers(responseData.data);
    setTotal(responseData.total);
  } catch (error) {
    console.error("Failed to fetch team members:", error);
  }
};

export const handleDownloadCSV = (filteredData) => {
  const csvContent = [
    ["Organization Name", "Manager Email", "Current Capacity", "Max Capacity"],
    ...filteredData.map((item) => [
      item.name || "N/A",
      item.manager_email || "N/A",
      item.current_capacity,
      item.max_capacity || "N/A",
    ]),
  ]
    .map((e) => e.join(","))
    .join("\n");

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", "organizations_data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const deleteOrg = async (id, filteredData, setFilteredData) => {
  try {
    const response = await postAuth(`/organization/deactivate-organization`, {
      organization_id: id,
    });
    await response.json();
    if (response.ok) {
      alert("Organization deleted successfully");
      const updatedOrgs = filteredData.filter((org) => org.id !== id);
      setFilteredData(updatedOrgs);
    }
  } catch (error) {
    console.error("Failed to delete organization:", error);
  }
};

export const editOrg = async (organization) => {
  try {
    const response = await putAuth(`/organization/update-organization`, {
      id: organization.id,
      name: organization.name,
      manager_email: organization.manager_email,
      current_capacity: organization.current_capacity,
      max_capacity: organization.max_capacity,
    });
    await response.json();
    if (response.ok) {
      alert("Organization updated successfully");
    }
  } catch (error) {
    alert("Failed to update organization");
    console.error("Failed to update organization:", error);
  }
};

export const formatDate = (apiDate) => {
  if (!apiDate) {
    return "";
  }

  // Remove the 'Z' to prevent UTC conversion
  const localDateString = apiDate.replace("Z", "");

  // Create a new Date object using the local time string
  const date = new Date(localDateString);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return date.toLocaleDateString(undefined, options);
};

export const regenerateCode = async (id, setLoading) => {
  try {
    setLoading(true);
    const response = await postAuth(`/organization/regenerate-code`, {
      organization_id: id,
    });
    await response.json();
    if (response.ok) {
      toast.success("Code regenerated successfully");
    }
  } catch (error) {
    toast.error("Failed to regenerate code");
    console.error("Failed to regenerate code:", error);
  } finally {
    setLoading(false);
  }
};
