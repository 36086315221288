import React, { useContext, useEffect, useRef, useState } from "react";
import { Draggable } from "@hello-pangea/dnd";
import { EventSourceContext } from "../sse-context/EventSourceContext";
import Post from "../post/Post";
import Popup from "../post/post-components/TelegramWidget";
import { website } from "../../global/globalUtils";
import { showToast, translateTexts } from "./column-utils/ColumnUtils";
import EditPopup from "../column_popups/EditPopup";
import { getPostsFromApi } from "../post/utils";
import { useSearch } from "../side-panel/sidepanel-components/global-search/SearchContext";
import {
  Column,
  ColumnBody,
  LoadButton,
} from "./column-components/ColumnStyles";
import ColumnHead from "./column-components/ColumnHeader";

const DraggableColumn = ({ static_column, index }) => {
  const { subscribe, unsubscribe } = useContext(EventSourceContext);
  const { searchState, globalDate, globalColumns } = useSearch();

  // this is the state for the posts
  const [localMessages, setLocalMessages] = useState([]);

  // this is the state for the column
  const [column, setColumn] = useState(static_column);
  // reference for the column body
  const columnRef = useRef();

  // this is the state for the search input and its debounced value
  const [inputValue, setInputValue] = useState(() => {
    return localStorage.getItem(`inputValue-${column.id}`) || "";
  });
  const [debouncedValue, setDebouncedValue] = useState("");

  // state for opening the edit popup
  const [isEditPopupVisible, setEditPopupVisible] = useState(false);

  // these are states for the post popup for telegram widget
  const [isOpen, setIsOpen] = useState(false);
  const [currentsender, setCurrentSender] = useState("");
  const [currentId, setCurrentPost] = useState("");

  // states for pagination
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [loading, setLoading] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);

  // states for the date input
  const [dateValue, setDateValue] = useState("");

  // state for sending the correct date to the API
  const [firstInteractionTime, setFirstInteractionTime] = useState(
    new Date().toLocaleString()
  );
  const [isLoadBeforeDate, setIsLoadBeforeDate] = useState(true);

  const [language, setLanguage] = useState("original");
  const [translatedMessages, setTranslatedMessages] = useState(new Map());
  const [translationLoading, setTranslationLoading] = useState({});

  // fetch column data
  useEffect(() => {
    const fetchColumn = async () => {
      try {
        setColumn(static_column);
      } catch (error) {
        console.error("Failed to fetch column:", error);
      }
    };

    fetchColumn();
  }, [static_column]);

  // handler function for the edit popup
  const handlePopupEdit = async (column2, type) => {
    // Set the state of the clicked chip and reset others
    const updatedColumn = {
      ...column2,
      channels: column2.channels,
      get_text_only: 0,
      get_image: 0,
      get_video: 0,
      get_preview: 0,
    };
    // Toggle the state of the clicked chip
    updatedColumn[type] = column2[type] ? 0 : 1;
    try {
      setColumn(updatedColumn);

      setForceUpdate((f) => !f); // Toggle forceUpdate to trigger re-render
    } catch (error) {
      console.error("Failed to update column:", error);
    }
  };

  // handle post popup for telegram widget
  const handleOpenPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentPost(post_id);
    setIsOpen(true);
  };

  // Date functions
  const handleDateChange = (event) => {
    setIsLoadBeforeDate(true);
    const localDate = event.target.value.toLocaleString();
    setDateValue(localDate);
  };

  const updateTimestamp = () => {
    const utcDate = new Date();
    const localDate = utcDate.toLocaleString();
    setIsLoadBeforeDate(true);
    setFirstInteractionTime(localDate);
  };

  useEffect(() => {
    const utcDate = new Date();
    const localDate = utcDate.toLocaleString();
    setFirstInteractionTime(localDate);
  }, [column]);

  // handle debouncing for the search input
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // global search
  useEffect(() => {
    if (globalColumns.length === 0) {
      // If globalColumns is empty, apply the search state to all columns
      setDebouncedValue(searchState);
    } else if (globalColumns.includes(column.id)) {
      // If globalColumns is not empty, apply the search state only if the current column's ID is included
      setDebouncedValue(searchState);
    }
  }, [searchState, globalColumns, column.id]);

  // load more posts for pagination
  const loadMorePosts = async (isLoadBeforeDate) => {
    if (page >= totalPages || loading) return;

    setLoading(true);
    setIsLoadBeforeDate(isLoadBeforeDate);
    const nextPage = page + 1;

    let dateToSend = firstInteractionTime; // Default date
    if (globalDate) {
      // Apply the date only if globalColumns is empty or includes this column's ID
      if (globalColumns.length === 0 || globalColumns.includes(column.id)) {
        dateToSend = new Date(globalDate).toLocaleString();
      }
    } else if (dateValue) {
      dateToSend = new Date(dateValue).toLocaleString();
    }

    const result = await getPostsFromApi({
      column: column,
      search: debouncedValue || inputValue,
      page: nextPage,
      limit: 10,
      date: dateToSend,
      isLoadBeforeDate: isLoadBeforeDate,
    });

    if (result.data && result.data.length > 0) {
      setLocalMessages((prevMessages) =>
        isLoadBeforeDate
          ? [...prevMessages, ...result.data]
          : [...result.data, ...prevMessages]
      );
      setPage(nextPage);
      setTotalPages(result.totalPages || totalPages);
    }

    setLoading(false);
  };

  // handle live messages
  useEffect(() => {
    const handleMessage = (newMessage) => {
      if (dateValue) {
        return;
      }
      column.channels.forEach(function (channel) {
        if (channel.id === newMessage.sender_id) {
          if (
            newMessage.text &&
            (!newMessage.text.includes(debouncedValue) ||
              (searchState && !newMessage.text.includes(searchState)))
          ) {
            return;
          }

          if (column.get_image && !newMessage.has_image) {
            return;
          }
          if (column.get_preview === 1 && !newMessage.has_preview) {
            return;
          }
          if (column.get_video && !newMessage.has_video) {
            return;
          }
          if (
            column.get_text_only &&
            (newMessage.has_image ||
              newMessage.has_video ||
              newMessage.has_preview)
          ) {
            return;
          }

          newMessage.chat_title = channel.chat_title;
          newMessage.sender_username = channel.sender_username;
          newMessage.image = `${website}${channel.image}`;
          newMessage.isApi = true;
          newMessage.id = newMessage.sender_id + "" + newMessage.post_id;
          setLocalMessages((prevMessages) => [newMessage, ...prevMessages]);

          if (column.notifications) {
            showToast(
              column.name,
              index,
              newMessage.text,
              channel.sender_username,
              column.isLoudSound,
              newMessage.id,
              column.id
            );
          }
        }
      });
    };

    subscribe(handleMessage);
    return () => unsubscribe(handleMessage);
  }, [
    subscribe,
    unsubscribe,
    column.content,
    inputValue,
    column,
    dateValue,
    searchState,
  ]);

  // fetch posts from the database
  useEffect(() => {
    const fetchPosts = async () => {
      let dateToSend = firstInteractionTime; // Default date

      if (globalDate) {
        // Apply the date only if globalColumns is empty or includes this column's ID
        if (globalColumns.length === 0 || globalColumns.includes(column.id)) {
          dateToSend = new Date(globalDate).toLocaleString();
        }
      } else if (dateValue) {
        dateToSend = new Date(dateValue).toLocaleString();
      }

      const posts = await getPostsFromApi({
        column: column,
        search: debouncedValue,
        page: 1,
        limit: 10,
        date: dateToSend,
        isLoadBeforeDate: isLoadBeforeDate,
      });

      if (posts.data) {
        setLocalMessages(posts.data);
        setPage(2);
        setTotalPages(posts.totalPages || 1);
      }
    };

    fetchPosts();
  }, [
    debouncedValue,
    column,
    forceUpdate,
    dateValue,
    searchState,
    globalDate,
    globalColumns,
    isLoadBeforeDate,
    firstInteractionTime,
  ]);

  useEffect(() => {
    const translateMessages = async () => {
      if (language !== "original") {
        // Identify new messages that need translation
        const newMessages = localMessages.filter(
          (msg) => !translatedMessages.has(msg.id)
        );

        if (newMessages.length > 0) {
          const textsToTranslate = newMessages.map((message) => message.text);

          const loadingStates = {};
          newMessages.forEach((msg) => {
            loadingStates[msg.id] = true; // Set loading true for each new message
          });
          setTranslationLoading((prev) => ({ ...prev, ...loadingStates }));

          const translations = await translateTexts(
            textsToTranslate,
            language,
            setTranslationLoading
          );

          if (translations) {
            // Update the translatedMessages Map and localMessages
            const updatedMessages = [...localMessages]; // Copy existing messages

            newMessages.forEach((msg, index) => {
              // Update the Map with new translations
              translatedMessages.set(msg.id, translations[index] || msg.text);

              // Update the corresponding message in the copied messages
              const messageIndex = updatedMessages.findIndex(
                (m) => m.id === msg.id
              );
              if (messageIndex !== -1) {
                updatedMessages[messageIndex] = {
                  ...updatedMessages[messageIndex],
                  text:
                    translations[index] || updatedMessages[messageIndex].text, // Replace text with translation
                };
              }

              // Update loading state for each message
              setTranslationLoading((prev) => ({
                ...prev,
                [msg.id]: false, // Set loading false after translation is done
              }));
            });

            setTranslatedMessages(new Map(translatedMessages)); // Update state with the new Map
            setLocalMessages(updatedMessages); // Set the updated messages
          }
        }
      }
    };

    translateMessages();
  }, [language, localMessages]);

  return (
    <Draggable key={column.id} draggableId={column.id.toString()} index={index}>
      {(provided) => (
        <Column
          key={column.id}
          ref={provided.innerRef}
          {...provided.draggableProps}
          id={`column-${column.name}-${index}`}
        >
          <ColumnHead
            column={column}
            setColumn={setColumn}
            provided={provided}
            inputValue={inputValue}
            setInputValue={setInputValue}
            setEditPopupVisible={() => setEditPopupVisible(!isEditPopupVisible)}
            handleDateChange={handleDateChange}
            dateValue={dateValue}
            updateTimestamp={updateTimestamp}
            setForceUpdate={setForceUpdate}
            setLanguage={setLanguage}
          />
          <ColumnBody ref={columnRef}>
            {dateValue && !loading && (
              <LoadButton
                onClick={() => {
                  loadMorePosts(false);
                }}
              >
                Load More
              </LoadButton>
            )}
            {localMessages.map((msg, index) => (
              <div key={index} data-msg-index={index}>
                <Post
                  column={column}
                  post={msg}
                  onOpenPopup={handleOpenPopup}
                  translationLoading={translationLoading[msg.id] || false}
                />
              </div>
            ))}
            {isOpen && (
              <Popup
                senderusername={currentsender}
                post_id={currentId}
                onClose={() => setIsOpen(false)}
              />
            )}
            {page < totalPages && !loading && (
              <LoadButton
                onClick={() => {
                  loadMorePosts(true);
                }}
              >
                Load More
              </LoadButton>
            )}
          </ColumnBody>

          {isEditPopupVisible && (
            <EditPopup
              column={column}
              onClose={() => setEditPopupVisible(!isEditPopupVisible)}
              onSave={handlePopupEdit}
            />
          )}
        </Column>
      )}
    </Draggable>
  );
};

export default DraggableColumn;
