import React from "react";
import styled from "styled-components";
import { useFilter } from "../../../contexts/FilterContext";
import { website } from "../../../../../global/globalUtils";

// Styled components
const LegendContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // Two columns
  gap: 20px;
  padding: 20px;
  border-radius: 8px;
`;

const CategoryItem = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

const CategoryLabel = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.text};
`;

const KeyTab = () => {
  const { dbCategories } = useFilter();

  // Filter out categories with a null icon
  const filteredCategories = dbCategories.filter((category) => category.icon);

  return (
    <LegendContainer>
      {filteredCategories.map((category) => (
        <CategoryItem key={category.id}>
          <CategoryIcon
            src={`${website}${category.icon}`}
            alt={category.category}
          />
          <CategoryLabel>{category.category}</CategoryLabel>
        </CategoryItem>
      ))}
    </LegendContainer>
  );
};

export default KeyTab;
