import React, { useState } from "react";
import styled from "styled-components";
import {
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
} from "react-share";

// Modal background with a fade-in effect
export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  animation: fadeIn 0.3s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
`;

// Modal content box with shadow and padding
export const ModalContent = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.column_background};
  padding: 40px;
  border-radius: 15px;
  min-width: 300px;
  max-width: 80vw;
  max-height: 80vh;
  text-align: center;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

// Close button with hover effect
export const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 30px;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: ${({ theme }) => theme.column_text};
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.2);
  }
`;

// Button for editing text with smooth transitions and shadow
const EditButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 50px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
  margin-top: 20px;
  transition: background-color 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-3px);
  }
`;

// Text area for editing, with improved border and focus styles
const TextArea = styled.textarea`
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #ccc;
  resize: both;
  margin-top: 20px;
  width: 100%;
  background-color: ${({ theme }) => theme.column_background};
  color: ${({ theme }) => theme.column_text};
  font-size: 16px;
  transition: border 0.3s;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
`;

// Wrapper for the share buttons
const ShareButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 20px;
`;

const SharePopup = ({ isOpen, togglePopup, text, mediaUrl }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [modifiedText, setModifiedText] = useState(text);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleTextChange = (e) => {
    setModifiedText(e.target.value);
  };

  return (
    <>
      {isOpen && (
        <ModalBackground onClick={togglePopup}>
          <ModalContent
            onClick={(e) => e.stopPropagation()}
            isEditing={isEditing}
          >
            <CloseButton onClick={togglePopup}>×</CloseButton>
            {!isEditing && (
              <ShareButtonWrapper>
                <TwitterShareButton
                  url={" "}
                  title={text}
                  hashtag={`#teledeck`}
                >
                  <TwitterIcon size={40} round />
                </TwitterShareButton>
                <WhatsappShareButton url={" "} title={text}>
                  <WhatsappIcon size={40} round />
                </WhatsappShareButton>
                <TelegramShareButton url={" "} title={text}>
                  <TelegramIcon size={40} round />
                </TelegramShareButton>
              </ShareButtonWrapper>
            )}

            {isEditing ? (
              <TextArea
                value={modifiedText}
                onChange={handleTextChange}
                rows="6"
              />
            ) : null}

            <EditButton onClick={handleEditToggle}>
              {isEditing ? "Save" : "Edit Sent Text"}
            </EditButton>
          </ModalContent>
        </ModalBackground>
      )}
    </>
  );
};

export default SharePopup;
