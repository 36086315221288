import { postAuth } from "../../../global/globalUtils";
import { toast } from "sonner";

export const fetchChannelsWithTags = async (
  query,
  tags = [],
  chosenChannelIds = [],
  page = 1,
  setLoading
) => {
  try {
    // Include chosenChannelIds in the request payload
    const response = await postAuth(`/channel/?page=${page}`, {
      search: query,
      tags,
      exclude: chosenChannelIds,
    });

    if (!response.ok) {
      toast.error("Failed to fetch channels");
      return null;
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching channels with tags:", error);
    return null;
  }
};
