import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Select from "react-select";
import { DatePicker } from "../../../../../global/styled-components/DatePicker";
import { useTheme } from "../../../../themes/ThemeContext";
import { useFilter } from "../../../contexts/FilterContext";
import { groupCategories } from "./FilterTab_utils";

export const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 20px;
`;

const SectionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-size: 16px;
`;

const SearchButton = styled.button`
  background-color: #1e2836;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
`;

const StyledLabel = styled.label`
  font-size: 1em;
`;

const FiltersSidebar = () => {
  const { theme } = useTheme();
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setCategories,
    dbCategories,
  } = useFilter();

  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [localEndDate, setLocalEndDate] = useState(endDate);
  const [localCategories, setLocalCategories] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const groupedOptions = groupCategories(dbCategories);
      setOptions(groupedOptions);
    };
    fetchCategories();
  }, [dbCategories]);

  const applyFilters = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
    // Only pass an array of values (category IDs) to setCategories
    setCategories(localCategories.map((category) => category.value));
  };

  const resetFilters = () => {
    setLocalStartDate(null);
    setLocalEndDate(null);
    setLocalCategories([]);
    setStartDate(null);
    setEndDate(null);
    setCategories([]);
  };

  const hasFiltersApplied = () => {
    return localStartDate || localEndDate || localCategories.length > 0;
  };

  const formatGroupLabel = (data) => (
    <div style={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}>
      {data.label}
    </div>
  );

  return (
    <>
      <h2 style={{ textAlign: "center" }}>Map Filters</h2>
      <SectionContainer>
        <SectionTitle>Choose the categories</SectionTitle>
        <StyledSelect
          isMulti
          className="toggle-button"
          value={localCategories}
          onChange={setLocalCategories}
          options={options}
          placeholder="Select Category (Optional)"
          formatGroupLabel={formatGroupLabel}
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: "14px",
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            singleValue: (base) => ({
              ...base,
              color: theme === "dark" ? "#fff" : "#000",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused
                ? theme === "dark"
                  ? "#555"
                  : "#ddd"
                : theme === "dark"
                ? "#333"
                : "#fff",
              color: state.isFocused
                ? theme === "dark"
                  ? "#fff"
                  : "#000"
                : theme === "dark"
                ? "#fff"
                : "#000",
              cursor: "pointer",
            }),
            input: (base) => ({
              ...base,
              color: theme.text,
            }),
            groupHeading: (base) => ({
              ...base,
              color: theme.text,
            }),
          }}
        />

        <SectionTitle>Set Date Interval</SectionTitle>
        <InputContainer>
          <StyledLabel>Start Date</StyledLabel>
          <DatePicker
            type="datetime-local"
            className="toggle-button"
            $theme={theme}
            value={localStartDate !== null ? localStartDate : ""}
            onChange={(e) => setLocalStartDate(e.target.value)}
            placeholder="Select Start Date"
          />
        </InputContainer>
        <InputContainer>
          <StyledLabel>End Date</StyledLabel>
          <DatePicker
            type="datetime-local"
            className="toggle-button"
            $theme={theme}
            value={localEndDate !== null ? localEndDate : ""}
            onChange={(e) => setLocalEndDate(e.target.value)}
            placeholder="Select End Date"
          />
        </InputContainer>
      </SectionContainer>
      <SearchButton onClick={applyFilters}>Apply Filters</SearchButton>
      {hasFiltersApplied() && (
        <SearchButton
          onClick={resetFilters}
          style={{ backgroundColor: "red", marginTop: "10px" }}
        >
          Reset Filters
        </SearchButton>
      )}
    </>
  );
};

export default FiltersSidebar;
