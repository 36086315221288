import React from "react";
import styled from "styled-components";

const ItemContainer = styled.div`
  width: 200px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  color: white;
  box-sizing: border-box;
  padding: 20px 10px;
  background-color: ${({ theme }) => theme.nav_background};

  &:hover {
    background-color: ${({ theme }) => theme.nav_hover};
  }
`;

const ItemText = styled.div`
  font-size: 14px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
  max-width: 100px;
`;

const ItemIcon = styled.i`
  font-size: 24px;
  color: white;
`;

const ItemNumber = styled.div`
  padding: 2px 6px;
  border-radius: 12px;
  font-size: 12px;
`;

const NavItem = ({ icon, name, number, onClick, isClickable }) => {
  const handleClick = () => {
    if (onClick && isClickable) {
      onClick();
    }
  };

  return (
    <ItemContainer
      onClick={handleClick}
      style={{ pointerEvents: isClickable ? "auto" : "none" }}
    >
      <ItemIcon className="material-icons">{icon}</ItemIcon>
      <ItemText>{name}</ItemText>
      <ItemNumber>{number}</ItemNumber>
    </ItemContainer>
  );
};

export default NavItem;
