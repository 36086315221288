import ColumnsContainer from "../components/column/ColumnsContainer";
import SideBar from "../components/sidebar/SideBar";
import useUserTimeTracker from "../global/UserTimeTracker";
import { EventSourceProvider } from "../components/sse-context/EventSourceContext";
import { ColumnsProvider } from "../components/column/ColumnsContext";

const Main = () => {
  useUserTimeTracker();

  return (
    <EventSourceProvider>
      <ColumnsProvider>
        <SideBar>
          <ColumnsContainer />
        </SideBar>
      </ColumnsProvider>
    </EventSourceProvider>
  );
};

export default Main;
