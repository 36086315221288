import React, { useState } from "react";
import styled from "styled-components";
import { postAuth } from "../../../../global/globalUtils";
import Swal from "sweetalert2";
import {
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Styled components
const Section = styled.section`
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
  box-shadow: 4px 0px 6px rgba(0, 0, 0, 0.1), -4px 0px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: row;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
  max-width: 400px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #007bff;
  }
`;

const Button = styled.button`
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #0056b3;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const CheckboxLabel = styled(Label)`
  margin-left: 8px;
  margin-bottom: 0; // Ensure the label is vertically centered
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  flex: 1;
`;

const CreateOrganizationForm = () => {
  const [organizationName, setOrganizationName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [managerPassword, setManagerPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [maxCapacity, setMaxCapacity] = useState("");
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const validatePassword = (password) => {
    return password.length >= 8; // You can add more conditions if needed
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(managerPassword)) {
      Swal.fire(
        "Error",
        "Password must be at least 8 characters long.",
        "error"
      );
      return;
    }
    setLoading(true);
    try {
      const response = await postAuth(`/organization/create`, {
        name: organizationName,
        email: managerEmail,
        password: managerPassword,
        max_capacity: maxCapacity,
      });
      const responseData = await response.json();
      if (responseData.status === "success") {
        Swal.fire({
          title: "Organization Created",
          text: `The organization code is:  ${responseData.code}`,
          icon: "success",
        });
      } else {
        Swal.fire("Error", responseData.message, "error");
      }
    } catch (error) {
      console.error("Failed to create organization:", error);
      Swal.fire("Error", "Failed to create organization", "error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="div">
          Create Organization
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Form onSubmit={handleSubmit}>
          <LeftColumn>
            <FormGroup>
              <Label htmlFor="organizationName">Organization Name:</Label>
              <Input
                type="text"
                id="organizationName"
                value={organizationName}
                onChange={(e) => setOrganizationName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="maxCapacity">Max Capacity:</Label>
              <Input
                type="number"
                id="maxCapacity"
                value={maxCapacity}
                onChange={(e) => setMaxCapacity(e.target.value)}
                required
                min="1"
              />
            </FormGroup>
          </LeftColumn>
          <RightColumn>
            <FormGroup>
              <Label htmlFor="managerEmail">Organization Manager Email:</Label>
              <Input
                type="email"
                id="managerEmail"
                value={managerEmail}
                onChange={(e) => setManagerEmail(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="managerPassword">
                Organization Manager Password:
              </Label>
              <Input
                type={passwordVisible ? "text" : "password"}
                id="managerPassword"
                value={managerPassword}
                onChange={(e) => setManagerPassword(e.target.value)}
                required
              />
              <CheckboxContainer>
                <input
                  type="checkbox"
                  id="togglePassword"
                  checked={passwordVisible}
                  onChange={togglePasswordVisibility}
                />
                <CheckboxLabel htmlFor="togglePassword">
                  Show Password
                </CheckboxLabel>
              </CheckboxContainer>
            </FormGroup>
          </RightColumn>
        </Form>
        <Button onClick={handleSubmit} disabled={loading}>
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Create Organization & Admin"
          )}
        </Button>
      </AccordionDetails>
    </Accordion>
  );
};

export default CreateOrganizationForm;
