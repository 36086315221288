import React, { useState, useEffect } from "react";
import { ContentRow } from "../components/ContentRow";
import { getMyProfile } from "./Profile_utils";
import CircularProgress from "@mui/material/CircularProgress";
import "../../../../assets/css/edtitableContentRow.css";
import { EditableContentRow } from "./EditableContentRow";

const Profile = () => {
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      try {
        const result = await getMyProfile();
        setProfile({
          firstName: result.first_name,
          lastName: result.last_name,
          email: result.email,
          phone: result.phone,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch profile:", error);
        setIsLoading(false);
      }
    };

    getProfile();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <EditableContentRow
            title={"First Name"}
            value={profile.firstName || ""}
            attributeKey={"first_name"}
          />
          <EditableContentRow
            title={"Last Name"}
            value={profile.lastName || ""}
            attributeKey={"last_name"}
          />
          <EditableContentRow
            title={"Phone Number"}
            value={profile.phone || ""}
            attributeKey={"phone"}
          />
          <ContentRow title={"Email"}>{profile.email}</ContentRow>
          {/* <Button
            onClick={handleOpenPopup}
            sx={{
              height: "39px",
              width: "140px",
              fontWeight: "inherit",
              fontSize: "14px",
              marginTop: "10px",
              backgroundColor: "#007bff",
              color: "white",
              "&:hover": {
                backgroundColor: "#002379",
              },
              borderRadius: "4px",
              textTransform: "none",
            }}
          >
            Change Password
          </Button>

          <PasswordChangePopup isOpen={showPopup} onClose={handleClosePopup} />

          <Button
            variant="contained"
            color="error"
            sx={{
              height: "39px",
              width: "140px",
              zIndex: 1,
              fontWeight: "inherit",
              textTransform: "none",
              fontSize: "14px",
              borderRadius: "4px",
            }}
            onClick={deactivateAccount}
          >
            Deactivate Now
          </Button> */}
        </>
      )}
    </>
  );
};
export default Profile;
