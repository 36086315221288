import { useTheme } from "styled-components";

export const useCustomStyles = () => {
  const theme = useTheme();

  return {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40,
      backgroundColor: theme.popup_background,
      color: theme.text,
      width: "140px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0 6px",
      color: theme.text,
    }),
    singleValue: (base) => ({
      ...base,
      color: theme.text,
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0,
      color: theme.text,
    }),
    indicatorsContainer: (base) => ({
      ...base,
      height: 40,
    }),
    menu: (base) => ({
      ...base,
      backgroundColor: theme.popup_background,
      color: theme.text,
    }),
    menuList: (base) => ({
      ...base,
      backgroundColor: theme.popup_background,
      color: theme.text,
    }),
    option: (base, { isFocused, isSelected }) => ({
      ...base,
      backgroundColor: isSelected
        ? theme.chip_active
        : isFocused
        ? theme.chip_inactive
        : theme.popup_background,
      color: theme.text,
      ":hover": {
        backgroundColor: theme.hover_background,
        color: theme.hover_text,
      },
      ":active": {
        backgroundColor: isSelected ? theme.chip_active : theme.chip_inactive,
      },
    }),
  };
};
