import React, { useRef, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useTheme } from "../../themes/ThemeContext";
import { useBookmarks } from "../../side-panel/sidepanel-components/bookmarks/BookmarkContext";

// Keyframes for the opening animation
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 105%;
  left: 50%;
  transform: translateX(-50%);
`;

const TooltipContainer = styled.div`
  width: 130px;
  background-color: ${({ theme }) =>
    theme === "light" ? "#d0d0d0" : "#222f3e"};
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  animation: ${({ $isOpen }) => ($isOpen ? fadeIn : fadeOut)} 0.3s ease forwards;
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
`;

const Tip = styled.div`
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid
    ${({ theme }) => (theme === "light" ? "#d0d0d0" : "#222f3e")};
`;

const CategoryList = styled.div`
  max-height: 160px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme === "light" ? "#a0a0a0" : "#444"};
    transition: background-color 0.3s linear;
  }
  &:hover {
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) =>
        theme === "light" ? "#808080" : "#222"};
      transition: background-color 0.3s linear;
    }
  }
`;

const Category = styled.div`
  padding: 8px 12px;
  z-index: 3;
  font-size: 14px;
  color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
  border-bottom: 1px solid
    ${({ theme }) => (theme === "light" ? "#ddd" : "#333")};
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "light" ? "#c0c0c0" : "#455a64"};
    color: ${({ theme }) => (theme === "light" ? "#000" : "#fff")};
  }

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const NoCategoriesMessage = styled.div`
  padding: 8px 12px;
  font-size: 14px;
  color: ${({ theme }) => (theme === "light" ? "#555" : "#ccc")};
  text-align: center;
`;

const CategoryPopup = ({ isOpen, addBookmark, onClose }) => {
  const { theme } = useTheme();
  const { categories } = useBookmarks();
  const popupRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <Wrapper ref={popupRef}>
      <Tip theme={theme} />
      <TooltipContainer theme={theme} $isOpen={isOpen}>
        <CategoryList theme={theme}>
          {categories.length > 0 ? (
            categories.map((category, index) => (
                <Category
                key={index}
                theme={theme}
                onClick={() => addBookmark(category)}
              >
                {category}
              </Category>
            ))
          ) : (
            <NoCategoriesMessage theme={theme}>
              No categories available
            </NoCategoriesMessage>
          )}
        </CategoryList>
      </TooltipContainer>
    </Wrapper>
  );
};

export default CategoryPopup;
