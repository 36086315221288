import React, { useState, useEffect, useMemo, useRef } from "react";
import styled from "styled-components";
import {
  CategorySelect,
  defaultColumn,
  HeaderContainer,
  NoBookmarksMessage,
  SearchContainer,
  SearchIcon,
  SearchInput,
} from "../bookmarks/Bookmarks";
import { useBookmarks } from "../bookmarks/BookmarkContext";
import CopyPost from "./CopyPost";
import SharePopup from "./SharePopup";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { useTheme } from "../../../themes/ThemeContext";
import blackLogo from "../../../../assets/images/teledeck-no-phrase.png";
import whiteLogo from "../../../../assets/images/Teledeck.svg";
import { handleTextInput, mergeAllPosts } from "./utils";

// Height of the navbar
const NAVBAR_HEIGHT = "60px";

// This container will serve as the parent container for the whole page
const PageContainer = styled.div`
  display: flex;
  height: calc(100vh - ${NAVBAR_HEIGHT});
  margin-top: ${NAVBAR_HEIGHT}; /* Push the content below the navbar */
  overflow: hidden;
`;

const Navbar = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${NAVBAR_HEIGHT}; /* Set the navbar height */
  z-index: 1000;
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#222f3e")};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Logo = styled.img`
  width: 180px;
  cursor: pointer;
`;

const Title = styled.h1`
  font-size: 24px; /* Increased font size */
  font-weight: 700; /* Bolder weight */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle text shadow */
  margin: 0;
  text-align: center;
  flex-grow: 1;
  animation: fadeIn 1.5s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const NavLinksContainer = styled.div`
  display: flex;
  align-items: center;
`;

const NavLink = styled.div`
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.text};
  margin-left: 20px;
  display: flex;
  align-items: center;
  transition: color 0.3s ease;
  position: relative;

  &:hover {
    color: blue;
  }

  .material-icons {
    margin-left: 5px;
  }
`;

const ShareNavLink = styled(NavLink)`
  position: relative;
`;

const BookmarksContainer = styled.div`
  width: 400px;
  height: 100%;
  flex-shrink: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => (theme === "light" ? "#fff" : "#222f3e")};
  padding: 10px;
  border-right: 1px solid
    ${({ theme }) => (theme === "light" ? "#ddd" : "#333")}; /* Add a slight border on the right */

  &::-webkit-scrollbar {
    width: 6px; /* Make the scrollbar thinner */
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
      theme === "light" ? "#EBEBEB" : " rgba(255, 255, 255, 0.2)"};
    border-radius: 10px;
    transition: background-color 0.3s ease;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

function BookmarkEditPage() {
  const {
    bookmarkedPosts,
    categories,
    selectedCategories,
    setSelectedCategories,
  } = useBookmarks();
  const { theme } = useTheme();
  const [searchTerm, setSearchTerm] = useState("");
  const [media, setMedia] = useState(null);
  const editorRef = useRef(null);
  const navigate = useNavigate();
  const [isSharePopupOpen, setIsSharePopupOpen] = useState(false);
  const [textInput, setTextInput] = useState(() => {
    const savedText = localStorage.getItem("textInput");
    return savedText !== null ? savedText : "";
  });

  useEffect(() => {
    localStorage.setItem("textInput", textInput);
  }, [textInput]);

  useEffect(() => {
    if (editorRef.current && media) {
      const imgHtml = `<img src="${media}" width="600" height="400" alt="Media" />`;
      editorRef.current.insertContent(imgHtml);
      setMedia("");
    }
  }, [media]);

  const toggleSharePopup = () => {
    setIsSharePopupOpen(!isSharePopupOpen);
  };

  const handleEditorChange = (content, editor) => {
    setTextInput(content);
  };

  const filteredPosts = useMemo(() => {
    if (!bookmarkedPosts || typeof bookmarkedPosts !== "object") return [];

    // If no category is selected, return all posts from all categories
    if (selectedCategories.length === 0) {
      return Object.values(bookmarkedPosts).flatMap((posts) => posts);
    }

    // Filter posts based on selected categories
    return selectedCategories
      .flatMap((selected) => {
        const postsInCategory = bookmarkedPosts[selected.value];
        return postsInCategory ? postsInCategory : [];
      })
      .filter((post) => {
        const postText = post.text ? post.text.toLowerCase() : "";
        return postText.includes(searchTerm.toLowerCase());
      });
  }, [bookmarkedPosts, searchTerm, selectedCategories]);

  return (
    <>
      <Navbar theme={theme}>
        <Logo
          src={theme === "light" ? blackLogo : whiteLogo}
          alt="Logo"
          onClick={() => {
            navigate("/");
          }}
        />

        <Title>Bookmarks Text Editor</Title>

        <NavLinksContainer>
          <NavLink
            onClick={() => {
              mergeAllPosts(editorRef, filteredPosts);
            }}
          >
            Merge All
            <span className="material-icons">merge_type</span>
          </NavLink>
          <ShareNavLink
            onClick={() => {
              toggleSharePopup();
            }}
          >
            Share
            <span className="material-icons">share</span>
            <SharePopup
              isOpen={isSharePopupOpen}
              text={handleTextInput(textInput)}
            />
          </ShareNavLink>
          <NavLink
            onClick={() => {
              navigate("/");
            }}
          >
            Exit
            <span className="material-icons">exit_to_app</span>
          </NavLink>
        </NavLinksContainer>
      </Navbar>

      <PageContainer>
        <BookmarksContainer theme={theme}>
          <HeaderContainer>
            <SearchContainer $fullWidth>
              <SearchInput
                type="text"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon className="material-icons">search</SearchIcon>
            </SearchContainer>
            {/* Multi-select to filter by categories using React Select */}
            {!(
              categories.length === 1 && categories[0].label === "uncategorized"
            ) && (
              <CategorySelect
                isMulti
                options={categories.map((cat) => ({ label: cat, value: cat }))} // Get from context
                onChange={setSelectedCategories}
                placeholder="Select Categories"
                styles={{
                  control: (base, state) => ({
                    ...base,
                    fontSize: "16px",
                    backgroundColor: theme.inputBackground,
                    borderRadius: "8px",
                    color: theme.text,
                  }),
                  menu: (base) => ({
                    ...base,
                    backgroundColor: theme.inputBackground,
                    zIndex: 999999,
                  }),
                  singleValue: (base) => ({
                    ...base,
                    color: theme.textColor,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isFocused
                      ? theme === "dark"
                        ? "#555"
                        : "#ddd"
                      : theme === "dark"
                      ? "#333"
                      : "#fff",
                    color: state.isFocused
                      ? theme === "dark"
                        ? "#fff"
                        : "#000"
                      : theme === "dark"
                      ? "#fff"
                      : "#000",
                    cursor: "pointer",
                  }),
                  input: (base) => ({
                    ...base,
                    color: theme.text,
                  }),
                }}
              />
            )}
          </HeaderContainer>
          {filteredPosts.length > 0 ? (
            filteredPosts.map((post, index) => (
              <div
                key={post.post_id}
                style={{
                  paddingBottom:
                    index === filteredPosts.length - 1 ? "20px" : "0",
                }}
              >
                <CopyPost
                  post={post}
                  column={defaultColumn}
                  is_a_bookmark={true}
                  setTextInput={setTextInput}
                  setMedia={setMedia}
                />
              </div>
            ))
          ) : (
            <NoBookmarksMessage>No Bookmarked Posts</NoBookmarksMessage>
          )}
        </BookmarksContainer>
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          value={textInput}
          init={{
            height: "100%",
            width: "100%",

            skin: `${theme === "light" ? "oxide" : "oxide-dark"}`,
            content_css: `${theme === "light" ? "default" : "dark"}`,
            plugins:
              "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount",
            toolbar:
              "undo redo | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image media | alignleft aligncenter alignright alignjustify | indent outdent | numlist bullist",
            branding: false,
          }}
          onEditorChange={handleEditorChange}
          onInit={(evt, editor) => (editorRef.current = editor)}
        />
      </PageContainer>
    </>
  );
}

export default BookmarkEditPage;
