import { postAuth } from "../../global/globalUtils";

export const getPostsFromApi = async ({
  column,
  search,
  page = 1,
  limit = 10,
  date,
  isLoadBeforeDate,
}) => {
  try {
    const url =
      `/post?page=${page}&limit=${limit}` +
      (date ? `&date=${date}` : "") +
      `&isLoadBeforeDate=${isLoadBeforeDate}`;

    const formattedData = {
      channels: column.channels.map((channel) => channel.id),
      filters: {
        get_image: column.get_image,
        get_video: column.get_video,
        get_preview: column.get_preview,
        get_text_only: column.get_text_only,
      },
      search: search,
    };

    const response = await postAuth(url, formattedData);

    if (!response.ok) {
      throw new Error("Failed to fetch posts from API");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in getPostsFromApi:", error);
    throw error;
  }
};

export const downloadMedia = async (postIds, setLoading) => {
  try {
    setLoading(true);
    const response = await postAuth(`/post/media`, postIds);

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const contentType = response.headers.get("Content-Type");

    if (contentType.includes("application/zip")) {
      // Handle ZIP file download
      const blob = await response.blob();
      if (blob.size > 0) {
        downloadBlob(blob, "teledeck_media.zip");
      } else {
        console.error("Received an empty ZIP file.");
      }
    } else {
      // Handle direct stream if not a ZIP
      const blob = await response.blob();
      if (blob.size > 0) {
        // Try to extract the filename from the `Content-Disposition` header
        let disposition = response.headers.get("Content-Disposition");

        let filename = disposition
          ? disposition.split("filename=")[1].replace(/['"]/g, "") // Remove any quotes around the filename
          : `download.${contentType.split("/")[1]}`; // Fallback to a default filename if not provided
        filename = decodeURIComponent(filename);

        triggerDownload(URL.createObjectURL(blob), filename);
      } else {
        console.error("Received an empty file.");
      }
    }
  } catch (error) {
    console.error("Failed to download file:", error);
  } finally {
    setLoading(false);
  }
};

// Helper function to trigger a download using a blob URL or a direct URL
const triggerDownload = (url, filename) => {
  const a = document.createElement("a");
  a.href = url;
  a.download = filename || "download";
  a.style.display = "none";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

// Function to create a blob URL and trigger a download
const downloadBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  triggerDownload(url, filename);
  URL.revokeObjectURL(url);
};

export const formatDate = (isoDateString) => {
  const date = new Date(isoDateString);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date >= today;
  const isYesterday = date >= yesterday && date < today;

  // Use toLocaleString with UTC to keep the time the same as given
  const timeString = date.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  });
  const dateString = date.toLocaleDateString("en-US", {
    year: "2-digit", // Show last two digits of the year
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  });

  if (isToday) {
    return `${timeString}`;
  } else if (isYesterday) {
    return `Yesterday at ${timeString}`;
  } else {
    return `${dateString}-${timeString}`;
  }
};

export const formatDateWithAddedHours = (isoDateString) => {
  const date = new Date(isoDateString);

  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);

  const isToday = date >= today;
  const isYesterday = date >= yesterday && date < today;

  const timeString = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
  const dateString = date.toLocaleDateString("en-US");

  if (isToday) {
    return `${timeString}`;
  } else if (isYesterday) {
    return `Yesterday at ${timeString}`;
  } else {
    return `${dateString}-${timeString}`;
  }
};
