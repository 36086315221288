import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)(({ bgcolor, hovercolor, textcolor }) => ({
  backgroundColor: bgcolor || "#1976d2",
  color: textcolor || "#fff",
  "&:hover": {
    backgroundColor: hovercolor || "#115293",
  },
}));

export default StyledButton;
