import Swal from "sweetalert2";
import { post } from "../../../global/globalUtils";

export const loginUser = async (formData) => {
  const { email, password } = formData;

  const requestBody = {
    email,
    password,
  };

  try {
    const response = await post("/auth/login", requestBody);

    const result = await response.json();

    if (response.ok) {
      Swal.fire({
        icon: "success",
        title: "Login Successful",
        text: "Welcome to our platform!",
      });
      return result;
    } else {
      console.error("login Error:", result.message);
      return { success: false, message: result.message || "Login failed" };
    }
  } catch (error) {
    console.error("Network Error:", error);
    return { success: false, message: error.message || "Network Error" };
  }
};

export const forgotPassword = async (email) => {
  if (!email) {
    console.error("Email is required");
    return { success: false, message: "Email is required" };
  }

  const response = await post("/auth/forgot-password", { email: email });

  const data = await response.json();

  if (!response.ok) {
    console.error("Failed to send reset password email: ", data.message);
    return { success: false, message: data.message || "Failed to send email" };
  }

  if (data.status === "success") {
    return { success: true, message: "Email sent successfully" };
  } else {
    console.error("Failed to send reset password email: ", data.message);
    return { success: false, message: data.message || "Failed to send email" };
  }
};
