import React, { createContext, useContext, useState } from "react";

const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [dbCategories, setDbCategories] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [countries, setCountries] = useState(["IL", "PS", "LB"]);
  const [isFreeView, setIsFreeView] = useState(false);

  return (
    <FilterContext.Provider
      value={{
        categories,
        setCategories,
        dbCategories,
        setDbCategories,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
        countries,
        setCountries,
        isFreeView,
        setIsFreeView,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

// Custom hook to use the FilterContext
export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};
