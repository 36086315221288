import React, { createContext, useContext, useState, useEffect } from "react";
import {
  getAllPosts,
  addPost,
  deletePost,
  createCategory,
  getAllCategories,
  deleteSelectedCategories,
} from "../../../localdb/DBUtils";

const BookmarkContext = createContext();

export const BookmarkProvider = ({ children }) => {
  const [bookmarkedPosts, setBookmarkedPosts] = useState({});
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const allPosts = await getAllPosts();
        const categorizedPosts = groupPostsByCategory(allPosts);
        setBookmarkedPosts(categorizedPosts);

        const allCategories = await getAllCategories(); // Fetch all categories
        setCategories(allCategories); // Store the categories in state
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData();
  }, []);

  // Helper function to group posts by category
  const groupPostsByCategory = (posts) => {
    const grouped = posts.reduce((acc, post) => {
      const category = post.bookmark_category || "uncategorized";
      if (!acc[category]) acc[category] = [];
      acc[category].push(post);
      return acc;
    }, {});
    return grouped;
  };

  const createNewCategory = async (category) => {
    try {
      await createCategory(category);

      // Update categories array and bookmarkedPosts state after category creation
      setCategories((prevCategories) => [...prevCategories, category]); // Add new category to categories array

      setBookmarkedPosts((prevPosts) => ({
        ...prevPosts,
        [category]: [], // Add the new category with an empty array of posts
      }));
    } catch (error) {
      console.error(`Error creating category "${category}":`, error);
    }
  };

  const addPostToContext = async (post, category = "uncategorized") => {
    try {
      // Add the post to IndexedDB
      await addPost(post, category);

      // Update the state with the new post
      setBookmarkedPosts((prevPosts) => {
        const updatedPosts = { ...prevPosts };

        // If the category doesn't exist, initialize it
        if (!updatedPosts[category]) {
          updatedPosts[category] = [];
        }

        // Check if the post already exists in the category
        const postExists = updatedPosts[category].some(
          (p) => p.post_id === post.post_id
        );

        // If the post does not exist, add it to the beginning of the category array
        if (!postExists) {
          return {
            ...updatedPosts,
            [category]: [post, ...updatedPosts[category]], // Prepend the new post to the array
          };
        }

        return updatedPosts; // Return the previous state if post already exists
      });

      // Ensure the category is in the categories array
      setCategories((prevCategories) => {
        if (!prevCategories.includes(category)) {
          return [...prevCategories, category];
        }
        return prevCategories;
      });
    } catch (error) {
      console.error("Error adding post to IndexedDB:", error);
    }
  };

  const updateBookmarkStatus = (postId, isBookmarked) => {
    setBookmarkedPosts((prevPosts) => {
      const updatedPosts = { ...prevPosts };

      // Iterate over all categories to find and update the post's bookmark status
      for (const category in updatedPosts) {
        if (Array.isArray(updatedPosts[category])) {
          updatedPosts[category] = updatedPosts[category].map((post) => {
            if (post.post_id === postId) {
              return {
                ...post,
                isBookmarked, // Update the bookmark status
              };
            }
            return post;
          });
        }
      }

      return updatedPosts;
    });
  };

  // Function to delete a post from the context by postId and category
  const deleteContextPost = async (postId) => {
    try {
      deletePost(postId); // Delete the post from IndexedDB without specifying a category

      // Update the state after the post is deleted
      setBookmarkedPosts((prevPosts) => {
        const updatedPosts = { ...prevPosts };

        // Iterate over all categories to find and remove the post
        for (const category in updatedPosts) {
          if (Array.isArray(updatedPosts[category])) {
            updatedPosts[category] = updatedPosts[category].filter(
              (post) => post.post_id !== postId
            );

            // Remove the category if it becomes empty
            if (updatedPosts[category].length === 0) {
              delete updatedPosts[category];
            }
          }
        }

        return updatedPosts;
      });
    } catch (error) {
      console.error("Error deleting post from IndexedDB:", error);
    }
  };

  const clearSelectedCategories = async (categoriesToDelete) => {
    try {
      // Use the new function to delete only the specified categories from IndexedDB
      await deleteSelectedCategories(categoriesToDelete);

      // Update the state after deleting the specified categories
      setBookmarkedPosts((prevPosts) => {
        const updatedPosts = { ...prevPosts };

        // Remove the selected categories from the state
        categoriesToDelete.forEach((category) => {
          delete updatedPosts[category];
        });

        return updatedPosts;
      });

      // Update the categories list by filtering out the deleted ones
      setCategories((prevCategories) =>
        prevCategories.filter(
          (category) => !categoriesToDelete.includes(category)
        )
      );
    } catch (error) {
      console.error("Error clearing selected categories:", error);
    }
  };

  return (
    <BookmarkContext.Provider
      value={{
        bookmarkedPosts,
        categories,
        selectedCategories,
        setSelectedCategories,
        addPostToContext,
        deleteContextPost,
        createNewCategory,
        updateBookmarkStatus,
        clearSelectedCategories,
      }}
    >
      {children}
    </BookmarkContext.Provider>
  );
};

export const useBookmarks = () => {
  return useContext(BookmarkContext);
};
