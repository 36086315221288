import { website } from "../../../global/globalUtils";
import { toast } from "sonner";

export const handleLogout = async () => {
  const isConfirmed = window.confirm(
    "Are you sure? You will be logged out of your account."
  );
  if (isConfirmed) {
    performLogout();
  }
};

const performLogout = async () => {
  try {
    const response = await fetch(`${website}/api/auth/logout`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const data = await response.json();

    if (response.ok && data.status === "success") {
      window.location.href = "/signin";
    } else {
      console.error("Logout failed:", data.message);
      toast.error(data.message || "Logout Failed. Please try again.");
    }
  } catch (error) {
    console.error("Logout failed:", error.message);
    toast.error("Logout Failed. Please try again later.");
  }
};
