import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MuiLink from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Container from "@mui/material/Container";
import { useAuth } from "../auth-context/AuthContext";
import { loginUser, forgotPassword } from "./SignIn_utils";
import { showErrorAlert } from "../sign-up/SignUp_utils";
import Swal from "sweetalert2";
import { validateEmail } from "../sign-up/SignUp_utils";
import logo from "../../../assets/images/teledeck.png";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="./about-us">
        Teledeck
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const defaultTheme = createTheme();

export default function SignInSide() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    let interval = null;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    try {
      const emailError = validateEmail(email);
      if (emailError) {
        showErrorAlert("Validation Error", emailError);
        setIsSubmitting(false);
        return;
      }

      const formData = { email, password };
      const data = await loginUser(formData);
      if (data.status === "success") {
        setAuth({
          isAuthenticated: data.isVerified === 1 ? true : false,
          isVerified: data.isVerified === 1 ? true : false,
          role: data.role,
          isLoading: false,
          userId: data.userId,
        });

        Swal.fire({
          icon: data.isVerified === 1 ? "success" : "info",
          text:
            data.isVerified === 1
              ? "Login successful!"
              : "Please await approval from your admin",
        });

        if (data.isVerified === 1) {
          navigate("/", { replace: true });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Login failed",
          text: data.message || "An error occurred",
        });
      }
    } catch (error) {
      console.error("Login failed:", error);
      showErrorAlert("Login failed", error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleForgotPassword = async () => {
    if (countdown === 0) {
      setIsResending(true);
      setCountdown(30);

      const result = await forgotPassword(email);

      if (result.success === true) {
        Swal.fire({
          icon: "success",
          title: "Reset Link sent successfully!",
          text: "check your email to reset your password",
        });
      } else {
        console.error("Failed to send reset link");
        showErrorAlert(result.message || "Failed to resend reset link");
      }
      setIsResending(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={logo}
            alt="Your Logo"
            style={{ width: "300px", height: "300px" }}
          />

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={handleEmailChange}
              onInput={(e) => {
                if (e.target.value) {
                  e.target.setAttribute("value", e.target.value);
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={handlePasswordChange}
            />

            <Button
              type="submit"
              disabled={isSubmitting}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: "#6985C0" }}
            >
              {isSubmitting ? "Signing In..." : "Sign In"}
            </Button>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "column-reverse",
                alignContent: "space-around",
              }}
            >
              <Grid
                item
                sx={{
                  textAlign: "center",
                  mb: 2,
                }}
              >
                <Button
                  type="button"
                  onClick={handleForgotPassword}
                  disabled={isResending || countdown > 0}
                  sx={{
                    textDecoration: "underline",
                    mb: 1,
                    mt: 2,
                    textTransform: "none",
                    textAlign: "center",
                  }}
                >
                  {countdown > 0
                    ? `Resend in ${countdown} seconds`
                    : isResending
                    ? "Sending..."
                    : "Forgot Password?"}
                </Button>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 1,
                }}
              >
                <MuiLink component={Link} to="/SignUp" variant="body2">
                  {"Don't have an account? Sign Up"}
                </MuiLink>
              </Grid>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
