import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaFireAlt, FaSearch, FaTimes, FaChartLine } from "react-icons/fa";
import { useSearch } from "../global-search/SearchContext";
import Skeleton from "react-loading-skeleton";
import { getTrendingTopics } from "./utils";

const Header = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  position: relative;
`;

const TrendingList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5px;
`;

const TrendingItem = styled.li`
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 50px;
  background-color: ${({ theme }) => theme.popup_background};
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;

  &:hover {
    background-color: ${({ theme, $topThree }) =>
      $topThree ? "#d32f2f" : "#1e2836"};
    color: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    transform: translateY(-3px);

    svg {
      color: ${({ $topThree }) => ($topThree ? "#fff" : "#ff4500")};
    }
  }

  svg {
    margin-right: 10px;
    color: #ff4500;
  }
`;

const TopicName = styled.span`
  flex: 1;
  font-size: 18px;
  margin-right: 10px;
`;

const SelectedTopic = styled.div`
  position: relative;
  margin: 10px 0;
  text-align: center;
  color: #007bff;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  svg {
    margin-right: 8px;
  }

  strong {
    color: ${({ theme }) => theme.text};
  }
`;

const CancelButton = styled.button`
  background-color: transparent;
  border: none;
  color: #ff4500;
  cursor: pointer;
  transition: color 0.2s;
  margin-left: 10px;
  margin-bottom: 10px;

  &:hover {
    color: #d44;
  }

  svg {
    margin-right: 5px;
    font-size: 16px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  padding-bottom: 10px;
  padding-top: 0;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const Counter = styled.span`
  background-color: #007bff;
  color: white;
  font-weight: bold;
  padding: 5px;
  border-radius: 50%;
  font-size: 12px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 15px;
  min-height: 15px;
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  margin-left: 5px;
`;

const Select = styled.select`
  padding: 12px 0px 12px 10px;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:focus {
    outline: none;
    border-color: #007bff;
  }
`;

const TrendingTab = () => {
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [trendingData, setTrendingData] = useState([]);
  const [location, setLocation] = useState("anywhere");
  const [time, setTime] = useState(10);
  const [language, setLanguage] = useState("arabic");
  const { setSearchState } = useSearch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getTrendingTopics(setTrendingData, setLoading, language, location, time);
  }, [language, location, time]);

  const sortedTopics = [...trendingData]
    .sort((a, b) => b.count - a.count)
    .slice(0, 12);

  const handleTopicSelect = (topic) => {
    setSelectedTopic(topic);
    setSearchState(topic);
  };

  const handleCancelSearch = () => {
    setSelectedTopic(null);
    setSearchState("");
  };

  return (
    <>
      <Header>Trending Topics</Header>
      <InputContainer>
        <InputWrapper>
          <Label htmlFor="language">Language</Label>
          <Select
            id="language"
            value={language}
            onChange={(e) => setLanguage(e.target.value)}
          >
            <option value="arabic">Arabic</option>
            <option value="hebrew">Hebrew</option>
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="location">Location</Label>
          <Select
            id="location"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          >
            <option value="anywhere">Worldwide</option>
            <option value="lebanon">Lebanon</option>
            <option value="gaza">Gaza</option>
            <option value="palestine">Palestine</option>
            <option value="israel">Israel</option>
          </Select>
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor="time">Time Period</Label>
          <Select
            id="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          >
            <option value={10}>Trending Now</option>
            <option value={60}>Last Hour</option>
            <option value={6 * 60}>Last 6 Hours</option>
            <option value={24 * 60}>Last 24 Hours</option>
          </Select>
        </InputWrapper>
      </InputContainer>
      {selectedTopic && (
        <SelectedTopic>
          <FaSearch />
          Searching for:&nbsp;<strong>{selectedTopic}</strong>
          <CancelButton onClick={handleCancelSearch}>
            <FaTimes />
          </CancelButton>
        </SelectedTopic>
      )}
      <TrendingList>
        {loading ? (
          Array.from({ length: 14 }).map((_, index) => (
            <TrendingItem key={index}>
              <Skeleton
                width={25}
                height={25}
                style={{
                  marginRight: 10,
                }}
              />
              <Skeleton width={80} height={25} />
              <Skeleton
                width={25}
                height={25}
                style={{
                  marginLeft: 10,
                }}
              />
            </TrendingItem>
          ))
        ) : sortedTopics.length > 0 ? (
          sortedTopics.map((topic, index) => (
            <TrendingItem
              key={index}
              onClick={() => handleTopicSelect(topic.name)}
              $topThree={index < 3}
            >
              {index < 3 ? (
                time == 10 ? (
                  <FaChartLine />
                ) : (
                  <FaFireAlt />
                )
              ) : index >= 3 && index < 6 ? (
                <FaFireAlt />
              ) : null}
              <TopicName>{topic.name}</TopicName>
              <Counter>{topic.count}</Counter>
            </TrendingItem>
          ))
        ) : (
          <p style={{ textAlign: "center", color: "#888" }}>
            No trending topics available for the selected criteria
          </p>
        )}
      </TrendingList>
    </>
  );
};

export default TrendingTab;
