import React, { useState } from "react";
import styled from "styled-components";
import { useTheme } from "../../../themes/ThemeContext";
import { DatePicker } from "../../../../global/styled-components/DatePicker";
import {
  CheckBoxInput,
  RadioContainer,
} from "../../../column_popups/DownloadPopup";
import { useSearch } from "./SearchContext";
import Select from "react-select";
import Spinner from "../../../../global/loaders/Spinner";
import { useColumns } from "../../../column/ColumnsContext";
import { handleDownload, handleDownloadCSV } from "./SearchTab_utils";

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
  border-bottom: 1px solid #d3d3d3; /* Thin line at the bottom */
  box-shadow: 0 1px 2px -3px rgba(0, 0, 0, 0.1); /* Optional: subtle box shadow */
`;

const Tab = styled.div`
  padding: 10px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.3s;
  color: ${({ theme, $selected }) => ($selected ? theme.text : "#bcb8cb")};
  border-bottom: ${({ $selected }) =>
    $selected ? "2px solid #1e2836" : "none"};

  &:hover {
    background-color: ${({ theme }) =>
      theme.post_hover_background || "#f5f5f5"};
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -3px;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #1e2836;
    transform: ${({ $selected }) => ($selected ? "scaleX(1)" : "scaleX(0)")};
    transform-origin: bottom left;
    transition: transform 0.3s;
  }

  p {
    margin: 0 0 0 8px;
  }

  .material-icons {
    font-size: 24px;
  }
`;

const TabContent = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  padding-top: 20px;
  border-radius: 0 5px 5px 5px;
`;

const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 100%;
  padding-left: 40px !important;
`;

const StyledSelect = styled(Select)`
  font-size: 14px;
  width: 100%;
`;

const SectionTitle = styled.h4`
  color: ${({ theme }) => theme.text};
  margin: 10px 0;
  text-align: center;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const DateRangeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  & > div {
    width: 48%;
    text-align: center;
  }

  label {
    display: block;
    margin-bottom: 8px;
    text-align: center;
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  background-color: #1e2836;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }
`;

const SearchComponent = () => {
  const { theme } = useTheme();
  const { columns } = useColumns();
  const [From, setFrom] = useState("");
  const [To, setTo] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [searchDate, setSearchDate] = useState("");
  const [selectedLanguage, setSelectedLanguage] = useState({
    value: "Original",
    label: "Original",
  });
  const [mediaSelection, setMediaSelection] = useState({
    videos: false,
    images: false,
  });
  const [selectedColumns, setSelectedColumns] = useState([]);
  const { setSearchState, setGlobalDate, setGlobalColumns } = useSearch();
  const [CSVloading, setCSVLoading] = useState(false);
  const [MediaLoading, setMediaLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("csv");

  const handleFromChange = (e) => setFrom(e.target.value);
  const handleToChange = (e) => setTo(e.target.value);
  const handleLanguageChange = (selectedOption) =>
    setSelectedLanguage(selectedOption);
  const handleColumnChange = (selectedOptions) => {
    setSelectedColumns(selectedOptions);
    setGlobalColumns(selectedOptions.map((option) => option.value));
  };
  const toggleSelection = (mediaType) =>
    setMediaSelection((prevState) => ({
      ...prevState,
      [mediaType]: !prevState[mediaType],
    }));

  const handleSearch = (e) => setSearchInput(e.target.value);
  const handleSearchDateChange = (e) => setSearchDate(e.target.value);
  const handleSearchPress = () => {
    setSearchState(searchInput);
    setGlobalDate(searchDate);
  };

  return (
    <>
      <div className="search-top">
        <p className="search-instructions">
          Search All Columns<span className="material-icons">search</span>
        </p>

        <div className="input-icon-container">
          <ThemedInput
            type="text"
            className="ColumnNameInput TextArea"
            placeholder="Search..."
            value={searchInput}
            onChange={handleSearch}
            title="Press Enter to search"
          />
          <span className="material-icons search-icon">search</span>
        </div>

        <StyledSelect
          isMulti
          options={columns.map((column) => ({
            value: column.id,
            label: column.name,
          }))}
          value={selectedColumns}
          onChange={handleColumnChange}
          placeholder="Select Specific Columns (optional)"
          theme={theme}
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: "14px",
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            menu: (base) => ({
              ...base,
              backgroundColor: theme === "dark" ? "#333" : "#fff",
            }),
            singleValue: (base) => ({
              ...base,
              color: theme === "dark" ? "#fff" : "#000",
            }),
            option: (base, state) => ({
              ...base,
              backgroundColor: state.isFocused
                ? theme === "dark"
                  ? "#555"
                  : "#ddd"
                : theme === "dark"
                ? "#333"
                : "#fff",
              color: state.isFocused
                ? theme === "dark"
                  ? "#fff"
                  : "#000"
                : theme === "dark"
                ? "#fff"
                : "#000",
              cursor: "pointer",
            }),
            input: (base) => ({
              ...base,
              color: theme.text,
            }),
          }}
        />

        <DatePicker
          type="datetime-local"
          className="date-time-picker"
          $theme={theme}
          value={searchDate}
          onChange={handleSearchDateChange}
          title="Filter By Date"
        />

        <SubmitButton
          onClick={handleSearchPress}
          style={{
            marginTop: "10px",
          }}
        >
          Search
        </SubmitButton>
      </div>
      <>
        <p className="search-instructions download-afj">
          Global Download<span className="material-icons">download</span>
        </p>
        <TabsContainer>
          <Tab
            $selected={activeTab === "csv"}
            onClick={() => setActiveTab("csv")}
          >
            CSV Download
          </Tab>
          <Tab
            $selected={activeTab === "media"}
            onClick={() => setActiveTab("media")}
          >
            Media Download
          </Tab>
        </TabsContainer>

        <TabContent theme={theme}>
          {/* Date Range Inputs */}
          <DateRangeContainer>
            <div>
              <label>From</label>
              <DatePicker
                type="datetime-local"
                className="date-time-picker"
                $theme={theme}
                value={From}
                onChange={handleFromChange}
                fontSize={14}
                paddingHorizontal={"8px"}
              />
            </div>
            <div>
              <label>To</label>
              <DatePicker
                type="datetime-local"
                className="date-time-picker"
                $theme={theme}
                value={To}
                onChange={handleToChange}
                fontSize={14}
                paddingHorizontal={"8px"}
              />
            </div>
          </DateRangeContainer>

          {activeTab === "csv" && (
            <>
              <SectionTitle>Choose Text Language</SectionTitle>
              <InputContainer>
                <StyledSelect
                  options={[
                    { value: "Original", label: "Original" },
                    { value: "en", label: "English" },
                    { value: "ar", label: "Arabic" },
                    { value: "es", label: "Spanish" },
                    { value: "fr", label: "French" },
                    { value: "de", label: "German" },
                    { value: "ru", label: "Russian" },
                  ]}
                  value={selectedLanguage}
                  onChange={handleLanguageChange}
                  theme={theme}
                  styles={{
                    control: (base, state) => ({
                      ...base,
                      fontSize: "14px",
                      backgroundColor: theme === "dark" ? "#333" : "#fff",
                    }),
                    menu: (base) => ({
                      ...base,
                      backgroundColor: theme === "dark" ? "#333" : "#fff",
                    }),
                    singleValue: (base) => ({
                      ...base,
                      color: theme === "dark" ? "#fff" : "#000",
                    }),
                    option: (base, state) => ({
                      ...base,
                      backgroundColor: state.isFocused
                        ? theme === "dark"
                          ? "#555"
                          : "#ddd"
                        : theme === "dark"
                        ? "#333"
                        : "#fff",
                      color: state.isFocused
                        ? theme === "dark"
                          ? "#fff"
                          : "#000"
                        : theme === "dark"
                        ? "#fff"
                        : "#000",
                      cursor: "pointer",
                    }),
                    input: (base) => ({
                      ...base,
                      color: theme.text,
                    }),
                  }}
                />
              </InputContainer>
              {CSVloading ? (
                <Spinner />
              ) : (
                <SubmitButton
                  theme={theme}
                  onClick={() => {
                    handleDownloadCSV({
                      From,
                      To,
                      searchInput,
                      selectedLanguage,
                      setCSVLoading,
                    });
                  }}
                  disabled={CSVloading}
                >
                  Download CSV
                </SubmitButton>
              )}
            </>
          )}

          {activeTab === "media" && (
            <>
              <SectionTitle>Choose Media Types</SectionTitle>
              <InputContainer>
                <RadioContainer>
                  <CheckBoxInput>
                    <input
                      type="checkbox"
                      id="videos"
                      name="media"
                      checked={mediaSelection.videos}
                      onChange={() => toggleSelection("videos")}
                    />
                    <label htmlFor="videos">Videos</label>
                  </CheckBoxInput>
                  <CheckBoxInput>
                    <input
                      type="checkbox"
                      id="images"
                      name="media"
                      checked={mediaSelection.images}
                      onChange={() => toggleSelection("images")}
                    />
                    <label htmlFor="images">Images</label>
                  </CheckBoxInput>
                </RadioContainer>
              </InputContainer>
              {MediaLoading ? (
                <Spinner />
              ) : (
                <SubmitButton
                  theme={theme}
                  onClick={() => {
                    handleDownload({
                      From,
                      To,
                      searchInput,
                      mediaSelection,
                      setMediaLoading,
                    });
                  }}
                  disabled={MediaLoading}
                >
                  Download Media
                </SubmitButton>
              )}
            </>
          )}
        </TabContent>
      </>
    </>
  );
};

export default SearchComponent;
