export const groupCategories = (categories) => {
  const grouped = {};

  categories.forEach((category) => {
    if (category.parent_id) {
      // It's a subcategory
      const parentCategoryId = category.parent_id;
      if (!grouped[parentCategoryId]) {
        grouped[parentCategoryId] = {
          label:
            categories.find((c) => c.id === parentCategoryId)?.category ||
            "Unknown",
          options: [],
        };
      }
      grouped[parentCategoryId].options.push({
        label: category.category,
        value: category.id,
      });
    } else {
      // It's a main category
      if (!grouped[category.id]) {
        grouped[category.id] = {
          label: category.category,
          options: [],
        };
      }
    }
  });

  // Convert grouped object to array
  return Object.values(grouped);
};
