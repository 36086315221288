import React, { createContext, useContext, useRef } from "react";

// Create a context
const MapContext = createContext();

// Create a provider component
export const MapProvider = ({ children }) => {
  const mapRef = useRef(null); // Initialize the map ref

  return (
    <MapContext.Provider value={{ mapRef }}>{children}</MapContext.Provider>
  );
};

// Custom hook to use the map context
export const useMapRef = () => {
  return useContext(MapContext);
};
