import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Paper,
} from "@mui/material";
import { formatDate, getTeamMembers } from "./utils";

const ViewTeamPopup = ({ open, onClose, id }) => {
  const [teamMembers, setTeamMembers] = useState([]);
  const [total, setTotal] = useState(0); // Track the total number of team members
  const [page, setPage] = useState(1); // Start from page 1
  const [rowsPerPage, setRowsPerPage] = useState(8);

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1); // MUI passes zero-based page, so we increment to keep 1-based indexing
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to page 1 when rows per page change
  };

  // Fetch team members when id, page, or rowsPerPage change
  useEffect(() => {
    if (id) {
      getTeamMembers(id, setTeamMembers, page, rowsPerPage, setTotal); // Pass 1-based page to backend
    }
  }, [id, page, rowsPerPage]);

  const handleClose = () => {
    setPage(1);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Team Members</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Is Verified</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {teamMembers.map((member) => (
                <TableRow key={member.id}>
                  <TableCell>{member.email || "N/A"}</TableCell>
                  <TableCell>{member.role || "N/A"}</TableCell>
                  <TableCell>
                    {member.isVerified ? "Yes" : "No" || "N/A"}
                  </TableCell>
                  <TableCell>
                    {formatDate(member.created_at) || "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination Controls */}
        <TablePagination
          component="div"
          count={total} // Total number of team members from backend
          page={page - 1} // Convert to zero-based indexing for MUI pagination
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]} // Options for rows per page
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewTeamPopup;
