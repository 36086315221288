import React, { useRef, useEffect, useCallback, useMemo } from "react";
import "leaflet-geosearch/dist/geosearch.css";
import { MapContainer, TileLayer, LayersControl, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "@geoman-io/leaflet-geoman-free";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  fetchCategories,
  getPosts,
  scrollToAndHighlightPost,
  shouldFetch,
} from "../map_utils/MapUtiles";
import { debounce } from "lodash";
import { MyMarker } from "./MarkerAndPopup";
import { useFilter } from "../contexts/FilterContext";
import { toast } from "sonner";
import { useMapRef } from "../contexts/MapContext";
import { useLocations } from "../contexts/LocationsContext";
import { useClickedPost } from "../contexts/ClickedPostContext";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const MapComponent = () => {
  const { mapRef } = useMapRef();

  // map markers
  const { locations, setLocations, markersRef, markerClusterRef } =
    useLocations();

  // these are the filters
  const {
    countries,
    categories,
    startDate,
    endDate,
    isFreeView,
    setDbCategories,
  } = useFilter();

  const { setClickedPostId } = useClickedPost();

  const prevStartDateRef = useRef(null);
  const prevEndDateRef = useRef(null);
  const prevCountriesRef = useRef(countries);
  const prevCategoriesRef = useRef(categories);

  // Default values
  const center = L.latLng(32.5, 35.5);
  const bufferPercentage = 0.6;

  const prevCenterRef = useRef(null);
  const prevRadiusRef = useRef(null);

  // get post formatedCategories
  useEffect(() => {
    fetchCategories(setDbCategories);
  }, []);

  // get map posts
  const fetchData = useCallback(
    async (center, radius) => {
      const currentCenter = L.latLng(center);
      const currentRadius = radius;

      // Check if conditions have significantly changed
      const dateChanged =
        prevStartDateRef.current !== startDate ||
        prevEndDateRef.current !== endDate;
      const centerChanged =
        !prevCenterRef.current ||
        !prevCenterRef.current.equals(currentCenter) ||
        prevRadiusRef.current !== currentRadius;
      const countryChanged = prevCountriesRef.current !== countries;
      const categoryChanged = prevCategoriesRef.current !== categories;

      // Only check for center and radius changes if isFreeView is true
      const significantChange = isFreeView
        ? dateChanged || centerChanged || countryChanged || categoryChanged
        : dateChanged || countryChanged || categoryChanged;

      // If no significant change, skip fetching data
      if (!significantChange) return;

      const loadingToastId = toast.loading("Loading data, please wait...", {
        position: "bottom-center",
      });

      try {
        // Update refs with the new values
        prevCenterRef.current = currentCenter;
        prevRadiusRef.current = currentRadius;
        prevStartDateRef.current = startDate;
        prevEndDateRef.current = endDate;
        prevCountriesRef.current = countries;
        prevCategoriesRef.current = categories;

        // Fetch new posts based on current filters and conditions
        const newPosts = await getPosts(
          categories,
          countries,
          currentCenter,
          currentRadius,
          startDate,
          endDate,
          isFreeView
        );

        // Update the news state
        setLocations((prevItems) => {
          if (dateChanged || countryChanged || categoryChanged) {
            // If date, country, or category changed, replace the items
            return newPosts;
          } else {
            const existingIds = new Set(prevItems.map((post) => post.pid));
            const newIds = new Set(newPosts.map((post) => post.pid));

            // Filter existing posts to keep only those within the max distance
            const maxDistance = currentRadius * 2; // Define a max distance threshold
            const relevantExistingPosts = prevItems.filter((post) => {
              const postPosition = L.latLng([post.lat, post.lng]);
              return currentCenter.distanceTo(postPosition) <= maxDistance;
            });

            // If no change in dataset, return existing items
            if (
              relevantExistingPosts.length === prevItems.length &&
              newIds.size === existingIds.size &&
              [...existingIds].every((id) => newIds.has(id))
            ) {
              return prevItems; // Return existing items if no change
            }

            // Merge new posts, avoiding duplicates
            return [
              ...relevantExistingPosts,
              ...newPosts.filter((post) => !existingIds.has(post.pid)),
            ];
          }
        });
      } catch (error) {
        console.error("Error fetching posts:", error);
      } finally {
        toast.dismiss(loadingToastId);
      }
    },
    [categories, countries, startDate, endDate, isFreeView]
  );

  const debouncedFetchData = useCallback(
    debounce((center, radius) => {
      fetchData(center, radius);
    }, 500),
    [fetchData]
  );

  const UpdateCenterComponent = () => {
    const map = useMap();

    const prevCenter = useRef(map.getCenter());
    const prevRadius = useRef(0);

    useEffect(() => {
      // let circle = null;

      const updateCenterAndCircle = () => {
        const newCenter = map.getCenter();
        const bounds = map.getBounds();
        const northEast = bounds.getNorthEast();
        const newRadius = map.distance(newCenter, northEast) * bufferPercentage;
        const currentZoom = map.getZoom();

        // Only fetch data if there's a significant change in center or radius
        if (
          shouldFetch(
            prevCenter.current,
            prevRadius.current,
            newCenter,
            newRadius,
            currentZoom
          )
        ) {
          debouncedFetchData(newCenter, newRadius);
          prevCenter.current = newCenter;
          prevRadius.current = newRadius;
        }
      };

      updateCenterAndCircle();
      map.on("moveend", updateCenterAndCircle);

      return () => {
        map.off("moveend", updateCenterAndCircle);
      };
    }, [map]);

    return null;
  };

  // leaflet map effect ; clicks , mousedown , mouseup , etc
  const MapEffect = () => {
    const map = useMap();
    useEffect(() => {
      map.pm.addControls({
        position: "bottomleft",
        drawMarker: true,
        drawPolyline: true,
        drawRectangle: true,
        drawPolygon: true,
        drawCircle: true,
        editMode: true,
        dragMode: true,
        cutPolygon: true,
        removalMode: true,
      });
    }, [map]);
    return null;
  };

  const handleMarkerClick = (item) => {
    setClickedPostId(item.pid);
    setTimeout(() => {
      scrollToAndHighlightPost(item.pid);
    }, 1500);
  };

  const addMarkerToRef = (postId, marker) => {
    markersRef.current[postId] = marker; // Store marker reference
  };

  const markers = useMemo(() => {
    return locations.map((item, index) => (
      <MyMarker
        item={item}
        key={`${item.pid}-${index}`}
        index={index}
        handleMarkerClick={() => handleMarkerClick(item)}
        addMarkerToRef={addMarkerToRef} // Pass the function to MyMarker
      />
    ));
  }, [locations]);

  return (
    <div className="map-rotate-container">
      <MapContainer
        center={center}
        zoom={8}
        style={{ height: "100vh", width: "100%" }}
        ref={mapRef}
        maxZoom={18}
        minZoom={3}
        zoomControl={false}
      >
        <LayersControl position="bottomleft">
          <LayersControl.BaseLayer name="Street Map" checked>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              opacity={0.6}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer name="Satellite Imagery">
            <TileLayer url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" />
          </LayersControl.BaseLayer>
        </LayersControl>
        <MarkerClusterGroup
          spiderfyOnMaxZoom={true}
          showCoverageOnHover={false}
          spiderfyDistanceMultiplier={1.4}
          maxClusterRadius={1}
          ref={markerClusterRef}
        >
          {markers}
        </MarkerClusterGroup>
        <MapEffect />
        <UpdateCenterComponent />
      </MapContainer>
    </div>
  );
};

export default MapComponent;
