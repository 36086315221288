import React, { useState, useMemo, useEffect } from "react";
import { useBookmarks } from "./BookmarkContext";
import Post from "../../../post/Post";
import styled from "styled-components";
import Spinner from "../../../../global/loaders/Spinner";
import ExportCsv from "./ExportCsv";
import Select from "react-select";
import { useTheme } from "../../../themes/ThemeContext";
import { toast } from "sonner";
import { downloadMedia } from "../../../post/utils";

export const defaultColumn = {
  get_video: true,
  get_image: true,
  get_preview: true,
  get_text_only: true,
};

export const HeaderContainer = styled.h3`
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  gap: 10px;
  box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.1);
`;

export const InputContainer = styled.div`
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const ThemedInput = styled.input`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  width: 91%;
  margin: 0 auto;
  padding: 10px 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.inputBackground};
  border: 1px solid ${({ theme }) => theme.borderColor};
  border-radius: 8px;
  width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "50%")};
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  background: transparent;
  padding: 10px;
  padding-right: 0;
  font-size: 16px;
  color: ${({ theme }) => theme.text};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.placeholderColor};
  }
`;

export const CategorySelect = styled(Select)`
  background-color: ${({ theme }) => theme.inputBackground};
  flex-grow: 1;
  width: 100%;
`;

export const SearchIcon = styled.span`
  color: ${({ theme }) => theme.iconColor};
  font-size: 24px;
  cursor: pointer;
  margin-right: 10px;
`;

export const NoBookmarksMessage = styled.p`
  text-align: center;
  padding: 20px;
  color: gray;
  font-size: 1.2rem;
`;

const ButtonBase = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 5px;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const DownloadButton = styled(ButtonBase)`
  background-color: #007bff;
  color: white;

  &:hover {
    background-color: #0056b3;
  }
`;

const DeleteButton = styled(ButtonBase)`
  background-color: #dc3545;
  color: white;

  &:hover {
    background-color: #c82333;
  }
`;

export const ExportButton = styled(ButtonBase)`
  background-color: #28a745;
  color: white;

  &:hover {
    background-color: #218838;
  }
`;

const AddCategoryButton = styled(ButtonBase)`
  background-color: #ffc107;
  color: black;

  &:hover {
    background-color: #d39e00;
  }
`;

export const BookmarkComponent = ({
  setIsPostPopupOpen,
  setCurrentId,
  setCurrentSender,
  setIsDeleteModalOpen,
}) => {
  const {
    bookmarkedPosts,
    categories,
    createNewCategory,
    selectedCategories,
    setSelectedCategories,
  } = useBookmarks();
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { theme } = useTheme();

  const handleOpenPopup = (sender_username, post_id) => {
    setCurrentSender(sender_username);
    setCurrentId(post_id);
    setIsPostPopupOpen(true);
  };

  // Handle adding a new category
  const handleAddCategory = () => {
    const newCategory = prompt("Enter a new category name:");
    if (newCategory) {
      createNewCategory(newCategory);
    }
  };

  // Filter bookmarks based on the selected categories
  const filteredPosts = useMemo(() => {
    if (!bookmarkedPosts || typeof bookmarkedPosts !== "object") return [];

    // If no category is selected, return all posts from all categories
    if (selectedCategories.length === 0) {
      return Object.values(bookmarkedPosts).flatMap((posts) => posts);
    }

    // Filter posts based on selected categories
    return selectedCategories
      .flatMap((selected) => {
        const postsInCategory = bookmarkedPosts[selected.value];
        return postsInCategory ? postsInCategory : [];
      })
      .filter((post) => {
        const postText = post.text ? post.text.toLowerCase() : "";
        return postText.includes(searchTerm.toLowerCase());
      });
  }, [bookmarkedPosts, searchTerm, selectedCategories]);

  return (
    <>
      <HeaderContainer>
        <InputContainer>
          <SearchContainer>
            <SearchInput
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <SearchIcon className="material-icons">search</SearchIcon>
          </SearchContainer>
          <AddCategoryButton
            title="Add a new bookmarks category"
            onClick={handleAddCategory}
          >
            <span className="material-icons">bookmark_add</span>
          </AddCategoryButton>
          <ExportCsv bookmarkedPosts={bookmarkedPosts} />
          {loading ? (
            <Spinner loading={loading} />
          ) : (
            <DownloadButton
              title="Download all bookmarks media"
              onClick={() => {
                const postIds = Object.values(bookmarkedPosts).flatMap(
                  (posts) => posts.map((post) => post.id)
                );
                downloadMedia(postIds, setLoading);
              }}
            >
              <span className="material-icons">download</span>
            </DownloadButton>
          )}
          <DeleteButton
            title="Delete bookmark categories"
            onClick={() => {
              if (categories.length === 0) {
                toast.error("No categories available.");
              } else {
                setIsDeleteModalOpen(true);
              }
            }}
          >
            <span className="material-icons">delete</span>
          </DeleteButton>
        </InputContainer>
        {/* Multi-select to filter by categories using React Select */}
        {!(
          categories.length === 0 ||
          (categories.length === 1 && categories[0] === "uncategorized")
        ) && (
          <CategorySelect
            isMulti
            options={categories.map((cat) => ({ label: cat, value: cat }))} // Get from context
            onChange={setSelectedCategories}
            placeholder="Select Categories"
            styles={{
              control: (base, state) => ({
                ...base,
                fontSize: "16px",
                backgroundColor: theme.inputBackground,
                borderRadius: "8px",
                color: theme.text,
              }),
              menu: (base) => ({
                ...base,
                backgroundColor: theme.inputBackground,
                zIndex: 999999,
              }),
              singleValue: (base) => ({
                ...base,
                color: theme.textColor,
              }),
              option: (base, state) => ({
                ...base,
                backgroundColor: state.isFocused
                  ? theme === "dark"
                    ? "#555"
                    : "#ddd"
                  : theme === "dark"
                  ? "#333"
                  : "#fff",
                color: state.isFocused
                  ? theme === "dark"
                    ? "#fff"
                    : "#000"
                  : theme === "dark"
                  ? "#fff"
                  : "#000",
                cursor: "pointer",
              }),
              input: (base) => ({
                ...base,
                color: theme.text,
              }),
            }}
          />
        )}
      </HeaderContainer>

      {filteredPosts.length > 0 ? (
        filteredPosts.map((post, index) => (
          <div key={`${post.post_id}-${index}`}>
            <Post
              post={post}
              column={defaultColumn}
              is_a_bookmark={true}
              onOpenPopup={handleOpenPopup}
            />
          </div>
        ))
      ) : (
        <NoBookmarksMessage>No Bookmarked Posts</NoBookmarksMessage>
      )}
    </>
  );
};
