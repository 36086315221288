import React, { useState } from "react";
import styled from "styled-components";
import Modal from "react-modal";
import axios from "axios";
import { useTheme } from "../../../themes/ThemeContext";
import { ExportButton } from "./Bookmarks";

// New styles for modal content
const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => (theme === "dark" ? "#333" : "white")};
  padding: 20px;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 30%;
  max-width: 300px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  h2 {
    padding-left: 0;
  }
`;

const ModalButton = styled.button`
  padding: 10px 20px;
  margin: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

// Specific style for the Cancel button
const CancelButton = styled(ModalButton)`
  background-color: #c82333; // Dark red
  &:hover {
    background-color: #dc3545; // Light red
  }
`;

// Style for the language select dropdown
const LanguageSelect = styled.select`
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.borderColor};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

// Custom styles for the modal overlay
const customOverlayStyles = {
  overlay: {
    zIndex: 1000,
  },
};

// Set the root element for accessibility for the modal
Modal.setAppElement("#root");

const ExportCsv = ({ bookmarkedPosts }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [userLanguage, setUserLanguage] = useState("original");
  const { theme } = useTheme();

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleLanguageChange = (event) => {
    setUserLanguage(event.target.value);
  };

  const translateText = async (text, targetLang) => {
    if (targetLang === "original") return text;
    try {
      const response = await axios.post(
        "https://python.data.teledeck.news/translate",
        {
          text: text,
          lang: targetLang,
        }
      );
      return response.data.translated_text;
    } catch (error) {
      console.error("Translation API error:", error);
      return text; // Return original text if translation fails
    }
  };

  const export2csv = async () => {
    const header = [
      "channel_name",
      "channel_username",
      "date",
      "time",
      "text",
      "post_url",
    ];

    // Utility function to remove emojis and special characters
    const removeEmojisAndSpecialChars = (text) => {
      return text.replace(/[^\p{L}\p{N}\p{P}\p{Z}]|\*/gu, "");
    };

    const csv = await Promise.all(
      bookmarkedPosts.map(async (row) => {
        const translatedRow = await Promise.all(
          header.map(async (fieldName) => {
            if (fieldName === "date" || fieldName === "time") {
              if (row.timestamp) {
                const dateObj = new Date(row.timestamp);
                const dateString = dateObj.toLocaleDateString(userLanguage);
                const timeString = dateObj.toLocaleTimeString(userLanguage, {
                  timeStyle: "short",
                });

                return removeEmojisAndSpecialChars(
                  fieldName === "date" ? dateString : timeString
                );
              } else {
                return "";
              }
            } else {
              let actualFieldName = fieldName;
              if (fieldName === "channel_name") actualFieldName = "chat_title";
              if (fieldName === "channel_username")
                actualFieldName = "sender_username";

              const fieldValue = row[actualFieldName] ?? "";
              const cleanedValue =
                typeof fieldValue === "string"
                  ? removeEmojisAndSpecialChars(fieldValue.replace(/\n/g, " "))
                  : fieldValue;

              if (fieldName === "text") {
                const translatedValue = await translateText(
                  cleanedValue.toString(),
                  userLanguage
                );
                const decodedValue = decodeURIComponent(
                  encodeURIComponent(translatedValue)
                );
                return removeEmojisAndSpecialChars(decodedValue);
              } else {
                return cleanedValue;
              }
            }
          })
        );

        return translatedRow.join("+");
      })
    );

    csv.unshift(header.join("+"));
    const csvString = csv.join("\r\n");

    const blob = new Blob(["\uFEFF" + csvString], {
      type: "text/csv;charset=utf-8;",
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "bookmarked_posts.csv";
    a.click();

    setTimeout(() => {
      URL.revokeObjectURL(url);
    }, 100);

    closeModal();
  };

  return (
    <>
      <ExportButton onClick={openModal} title="Export Bookmarks as CSV">
        <i className="material-icons">upload_file</i>
      </ExportButton>
      <StyledModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        theme={theme}
        style={customOverlayStyles}
      >
        <h2>Select Language </h2>
        <LanguageSelect
          value={userLanguage}
          onChange={handleLanguageChange}
          theme={theme}
        >
          <option value="original">Original</option>
          <option value="en">English (United States)</option>
          <option value="fr">French (France)</option>
          <option value="es">Spanish (Spain)</option>
          <option value="ar">Arabic</option>
        </LanguageSelect>
        <ButtonContainer>
          <ExportButton onClick={export2csv} theme={theme}>
            Export
          </ExportButton>
          <CancelButton onClick={closeModal} theme={theme}>
            Cancel
          </CancelButton>
        </ButtonContainer>
      </StyledModal>
    </>
  );
};

export default ExportCsv;
