import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styled from "styled-components";
import { fetchOrgs } from "../Payments/utils";

// Styled components for the container with reduced max-width
const FiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

// Main SearchBar component
const SearchBar = ({
  filters,
  setFilters,
  organizations,
  setOrganizations,
}) => {
  const [localFilters, setLocalFilters] = useState(filters);

  useEffect(() => {
    fetchOrgs(setOrganizations);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters({ ...localFilters, [name]: value });
  };

  const handleApplyFilters = () => {
    setFilters(localFilters);
  };

  const handleResetFilters = () => {
    setLocalFilters({
      searchTerm: "",
      organization: "",
      timeSpent: "",
      role: "",
      startDate: "",
      endDate: "",
      verified: "",
    });
  };

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="h6" component="div">
          Filters Section
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FiltersContainer>
          <Grid container spacing={2}>
            {/* Search Term */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Search by Email"
                placeholder="Enter email..."
                variant="outlined"
                fullWidth
                // Smaller input
                name="searchTerm"
                value={localFilters.searchTerm}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Organization */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="organization-label" shrink>
                  Select Organization
                </InputLabel>
                <Select
                  labelId="organization-label"
                  name="organization_id"
                  value={localFilters.organization}
                  onChange={(e) => {
                    setLocalFilters({
                      ...localFilters,
                      organization: e.target.value,
                    });
                  }}
                  fullWidth
                  displayEmpty
                  label="Select Organization"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {organizations.map((org) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Time Spent */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="time-spent-label">
                  Sort by Time Spent
                </InputLabel>
                <Select
                  labelId="time-spent-label"
                  name="timeSpent"
                  value={localFilters.timeSpent}
                  onChange={handleInputChange}
                  label="Sort by Time Spent"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="most_time">Most Time</MenuItem>
                  <MenuItem value="least_time">Least Time</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Role */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="role-label">Role</InputLabel>
                <Select
                  labelId="role-label"
                  name="role"
                  value={localFilters.role}
                  onChange={handleInputChange}
                  label="Role"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                  <MenuItem value="business">Business Admin</MenuItem>
                  <MenuItem value="user">User</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Start Date */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                name="startDate"
                value={localFilters.startDate}
                onChange={handleInputChange}
              />
            </Grid>

            {/* End Date */}
            <Grid item xs={12} sm={6} md={4}>
              <TextField
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                fullWidth
                name="endDate"
                value={localFilters.endDate}
                onChange={handleInputChange}
              />
            </Grid>

            {/* Verified */}
            <Grid item xs={12} sm={6} md={4}>
              <FormControl fullWidth>
                <InputLabel id="is-verified">Is Verified</InputLabel>
                <Select
                  labelId="is-verified"
                  name="verified"
                  value={localFilters.verified}
                  onChange={handleInputChange}
                  label="Sort by Time Spent"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={1}>Yes</MenuItem>
                  <MenuItem value={0}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          {/* Apply and Reset Buttons */}
          <Grid container spacing={2} justifyContent="flex-start">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyFilters}
                sx={{
                  maxWidth: "300px",
                  margin: "20px 0",
                }}
              >
                Apply Filters
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleResetFilters}
                sx={{
                  maxWidth: "300px",
                  margin: "20px 0",
                  backgroundColor: "red",
                }}
              >
                Reset Filters
              </Button>
            </Grid>
          </Grid>
        </FiltersContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default SearchBar;
