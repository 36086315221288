import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import SidePanelTabs from "./SidePanel_Tabs";
import { useAuth } from "../authentication/auth-context/AuthContext";
import { useNavigate } from "react-router-dom";

const ToggleButton = styled.button`
  position: fixed;
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  right: ${(props) => (props.$show ? "430px" : "-8px")};
  top: ${(props) =>
    props.$top}px; /* Use $ prefix to make it a transient prop */
  z-index: 21;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  width: 60px;
  transition: right 0.3s ease; /* Add animation */

  &:hover {
    background-color: ${({ theme }) => theme.cancel_hover};
  }

  span {
    margin-left: 8px;
    color: #333;
    font-size: 16px;
    color: ${({ theme }) => theme.text};
  }
`;

const iconAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(180deg); }
`;

const Icon = styled.span`
  font-size: 30px !important;
  margin-right: 15px;
  transition: transform 0.3s ease;
  animation: ${iconAnimation} 0.3s forwards;
`;

const StaticIcon = styled.span`
  font-size: 30px !important;
  margin-right: 15px;
`;

const SidePanelButton = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [top, setTop] = useState(70);
  const [top2] = useState(140);
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const { role } = useAuth();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setShowSidebar((prev) => !prev);
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartY(e.clientY - top);
    e.preventDefault();
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      const newTop = e.clientY - startY;
      const windowHeight = window.innerHeight;
      const boundedTop = Math.max(0, Math.min(newTop, windowHeight - 50));
      setTop(boundedTop);
      e.preventDefault();
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startY]);

  return (
    <div>
      <ToggleButton
        $top={top}
        $show={showSidebar}
        onMouseDown={handleMouseDown}
        onClick={toggleSidebar}
      >
        <Icon className="material-symbols-outlined">
          {showSidebar ? "right_panel_open" : "right_panel_close"}
        </Icon>
      </ToggleButton>
      <SidePanelTabs show={showSidebar} />
    </div>
  );
};

export default SidePanelButton;
