import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import { deleteUser, editUserAPI, fetchUsers, formatDate } from "./UsersUtils";
import { formatToDatetimeLocal } from "../Payments/utils";

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* Center align items vertically */
  margin-top: 20px;

  & > button {
    margin: 0 5px;
  }

  .page-info {
    margin: 0 10px;
    font-weight: bold;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Table = () => {
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    searchTerm: "",
    organization: "",
    timeSpent: "",
    verified: null,
    startDate: "",
    endDate: "",
    role: "",
  });
  const [editUser, setEditUser] = useState({
    id: 0,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    role: "",
    organization_id: 0,
    isVerified: 0,
    total_time_spent: null,
    created_at: null,
  });
  const [organizations, setOrganizations] = useState([]);

  const handleEditChange = (e) => {
    setEditUser({
      ...editUser,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    fetchUsers(
      filters,
      setUsers,
      setLoading,
      setCount,
      currentPage,
      itemsPerPage
    );
  }, [currentPage, filters, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const totalPages = Math.ceil(count / itemsPerPage);

  // Open modal for editing an existing payment
  const handleEditClick = (item) => {
    setEditUser({
      id: item.id,
      first_name: item.first_name,
      last_name: item.last_name,
      phone: item.phone,
      email: item.email,
      role: item.role,
      organization_id: item.organization_id,
      isVerified: item.isVerified,
      total_time_spent: formatToDatetimeLocal(item.total_time_spent),
      created_at: formatToDatetimeLocal(item.created_at),
    });
    setEditModalOpen(true);
  };

  // Submit logic for both create and edit
  const handleEditSubmit = async () => {
    await editUserAPI(editUser);
    setEditModalOpen(false);
    fetchUsers(
      filters,
      setUsers,
      setLoading,
      setCount,
      currentPage,
      itemsPerPage
    );
  };

  return (
    <>
      <Dialog
        open={editModalOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setEditModalOpen(false);
          }
        }}
      >
        {" "}
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="First Name"
            name="first_name"
            type="text"
            value={editUser.first_name}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Last Name"
            name="last_name"
            type="text"
            value={editUser.last_name}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            name="phone"
            type="number"
            value={editUser.phone}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            type="email"
            value={editUser.email}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            label="Role"
            name="role"
            select
            value={editUser.role}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="business">Business Admin</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </TextField>
          <TextField
            margin="dense"
            label="Is Verified"
            name="isVerified"
            select
            value={editUser.isVerified}
            onChange={handleEditChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          >
            <MenuItem value={1}>Yes</MenuItem>
            <MenuItem value={0}>No</MenuItem>
          </TextField>
          <InputLabel>Edit Organization</InputLabel>
          <FormControl fullWidth margin="dense">
            <Select
              name="organization_id"
              value={editUser.organization_id}
              onChange={handleEditChange}
              fullWidth
            >
              {organizations.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditModalOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleEditSubmit} color="primary">
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
      <SearchBar
        filters={filters}
        setFilters={setFilters}
        organizations={organizations}
        setOrganizations={setOrganizations}
      />
      <p style={{ marginBottom: "20px" }}>Number Of Users: {count}</p>
      <div className="data-table-diagram">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Phone Number</th>
                  <th>Email</th>
                  <th>Role</th>
                  <th>Organization</th>
                  <th>Verification</th>
                  <th>Total Time Spent (hours)</th>
                  <th>Creation Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.length > 0 ? (
                  users.map((item) => (
                    <tr key={item.id}>
                      <td>{item.first_name || "N/A"}</td>
                      <td>{item.last_name || "N/A"}</td>
                      <td>{item.phone || "N/A"}</td>
                      <td>{item.email}</td>
                      <td>{item.role || "N/A"}</td>
                      <td>{item.Organization.name || "N/A"}</td>
                      <td>{item.isVerified ? "Verified" : "Not Verified"}</td>
                      <td>{item.total_time_spent || "N/A"}</td>
                      <td>
                        {item.created_at ? formatDate(item.created_at) : "N/A"}
                      </td>
                      <td>
                        <ButtonContainer>
                          <Button
                            onClick={() => handleEditClick(item)}
                            variant="contained"
                            sx={{
                              backgroundColor: "#ffc107", // Yellow color for "Edit"
                              color: "#000",
                              "&:hover": {
                                backgroundColor: "#e0a800",
                              },
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this user?"
                                )
                              ) {
                                deleteUser(item.id);
                              }
                            }}
                            variant="contained"
                            sx={{
                              backgroundColor: "red",
                              color: "#fff",
                              "&:hover": {
                                backgroundColor: "#cc0000",
                              },
                            }}
                            startIcon={<DeleteIcon />}
                          >
                            Delete
                          </Button>
                        </ButtonContainer>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="8">No users found</td>
                  </tr>
                )}
              </tbody>
            </table>
            <PaginationControls>
              <Button
                variant="contained"
                onClick={() => handlePageChange(1)}
                disabled={currentPage === 1}
              >
                First
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <span className="page-info">
                Page {currentPage} of {totalPages}
              </span>
              <Button
                variant="contained"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </Button>
              <Button
                variant="contained"
                onClick={() => handlePageChange(totalPages)}
                disabled={currentPage === totalPages}
              >
                Last
              </Button>
            </PaginationControls>
          </>
        )}
      </div>
    </>
  );
};

export default Table;
