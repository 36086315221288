import React from "react";
import styled from "styled-components";

const PreviewDiv = styled.div`
  display: flex;
  width: 290px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.background_preview};
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const PreviewContainer = styled.div`
  display: flex;
  width: 290px;
  height: 100%;
  margin: 5px;
  box-sizing: border-box;
`;
const PreviewBar = styled.img`
  width: 3px;

  background-color: ${({ theme }) => theme.blue};
`;

const PreviewImage = styled.img`
  width: 150px;
  height: auto;
  object-fit: cover;
  border-radius: 5px;
`;

const PreviewDetails = styled.div`
  padding-left: 10px;
  font-size: 13px;
  display: flex;
  color: ${({ theme }) => theme.column_text};
`;

const MAX_TITLE_LENGTH = 100;

const PreviewComponent = ({ preview_title, preview_image }) => {
  if (preview_title == null) return;

  let firstPreviewImage = null;

  if (preview_image) {
    firstPreviewImage = preview_image;
  }

  const truncatedTitle =
    preview_title.length > MAX_TITLE_LENGTH
      ? preview_title.substring(0, MAX_TITLE_LENGTH) + "..."
      : preview_title;

  return (
    <PreviewDiv>
      <PreviewBar />

      <PreviewContainer>
        {firstPreviewImage && (
          <PreviewImage src={firstPreviewImage} alt="Preview" />
        )}
        <PreviewDetails>{truncatedTitle}</PreviewDetails>
      </PreviewContainer>
    </PreviewDiv>
  );
};

export default PreviewComponent;
