import React, { useEffect, useRef, useState } from "react";
import {
  ColumnHeader,
  ColumnHeaderText,
  ColumnHeaderIcon,
  ColumnHeaderIconClick,
  ChipContainer,
  FiltersDropDown,
  ThemedInput,
  StrongWord,
  SearchHeader,
  DropdownMenu,
  DropdownItem,
} from "./ColumnStyles";
import { deleteColumn, duplicateColumn } from "../column-utils/ColumnUtils";
import FromToPopup from "../../column_popups/DownloadPopup";
import TranslateColumn from "../../column_popups/TranslatePopup";
import { DatePicker } from "../../../global/styled-components/DatePicker";
import FilterChips from "./Chips";
import { useColumns } from "../ColumnsContext";
import { useTheme } from "../../themes/ThemeContext";

const ColumnHead = ({
  column,
  setColumn,
  provided,
  inputValue,
  setInputValue,
  setEditPopupVisible,
  handleDateChange,
  dateValue,
  setLanguage,
  updateTimestamp,
  setForceUpdate,
}) => {
  const { updateColumn, columnsCount, addColumn, removeColumn } = useColumns();
  const { theme } = useTheme();
  const [isLangPopupVisible, setIsLangPopupVisible] = useState(false);
  const [isFromToPopupVisible, setFromToPopupVisible] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMoreVertDropdownVisible, setIsMoreVertDropdownVisible] =
    useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    if (isMoreVertDropdownVisible) setIsMoreVertDropdownVisible(false);
    setIsDropdownVisible(!isDropdownVisible);
  };
  const toggleMoreVertDropdown = () => {
    if (isDropdownVisible) setIsDropdownVisible(!isDropdownVisible);
    setIsMoreVertDropdownVisible(!isMoreVertDropdownVisible);
  };

  const handleInputChange = (event) => {
    updateTimestamp();
    const newValue = event.target.value;
    setInputValue(newValue);
    localStorage.setItem(`inputValue-${column.id}`, newValue);
  };

  // Close the dropdown if the click is outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
        setIsMoreVertDropdownVisible(false);
      }
    };

    // Add when the dropdown is open
    if (isDropdownVisible || isMoreVertDropdownVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownVisible, isMoreVertDropdownVisible]);

  return (
    <>
      <ColumnHeader>
        <ColumnHeaderIcon className="material-icons">
          {column.icon}
        </ColumnHeaderIcon>{" "}
        <ColumnHeaderText {...provided.dragHandleProps}>
          {column.name}
        </ColumnHeaderText>
        <ColumnHeaderIconClick
          className="material-icons"
          onClick={toggleDropdown}
        >
          tune
        </ColumnHeaderIconClick>
        <ColumnHeaderIconClick
          className="material-icons"
          onClick={toggleMoreVertDropdown}
        >
          more_vert
        </ColumnHeaderIconClick>
      </ColumnHeader>
      {isDropdownVisible && (
        <FiltersDropDown $isOpen={isDropdownVisible}>
          <SearchHeader>
            <div className="draggable-input-icon-container">
              <div className="left-side-search">
                <ThemedInput
                  type="text"
                  className="DraggableColumnNameInput TextArea"
                  placeholder="Search..."
                  value={inputValue}
                  onChange={handleInputChange}
                  title="Search For A Term"
                />
                <span className="material-icons search-icon">search</span>
              </div>
              <div className="right-side-icons">
                <span
                  className="material-icons column-header-icon"
                  onClick={() => {
                    setIsLangPopupVisible(true);
                  }}
                  title="Translate Column Text"
                >
                  translate
                </span>
                <span
                  className="material-icons column-header-icon"
                  onClick={() => {
                    setFromToPopupVisible(!isFromToPopupVisible);
                  }}
                  title="Download Column Media or CSV"
                >
                  download
                </span>
                {isLangPopupVisible && (
                  <TranslateColumn
                    setLanguage={setLanguage}
                    isOpen={isLangPopupVisible}
                    setIsOpen={setIsLangPopupVisible}
                  />
                )}
              </div>
            </div>
            <DatePicker
              type="datetime-local"
              value={dateValue}
              onChange={handleDateChange}
              className="date-time-picker"
              $theme={theme}
              title="Filter By Date"
            />
            {isFromToPopupVisible && (
              <FromToPopup
                isOpen={isFromToPopupVisible}
                onClose={() => setFromToPopupVisible(!isFromToPopupVisible)}
                search={inputValue}
                column={column.id}
              />
            )}
          </SearchHeader>

          <ChipContainer>
            {" "}
            <h5 className="column-header-filter-title">
              <StrongWord>Filters</StrongWord>
            </h5>
            <FilterChips
              column={column}
              setColumn={setColumn}
              setForceUpdate={setForceUpdate}
              updateColumn={updateColumn}
              updateTimestamp={updateTimestamp}
            />
          </ChipContainer>
        </FiltersDropDown>
      )}
      {isMoreVertDropdownVisible && (
        <div style={{ position: "relative" }}>
          <DropdownMenu ref={dropdownRef} $isOpen={isMoreVertDropdownVisible}>
            <DropdownItem onClick={setEditPopupVisible} theme={theme}>
              Edit Column
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                duplicateColumn(
                  column,
                  columnsCount,
                  addColumn,
                  toggleMoreVertDropdown
                );
              }}
              theme={theme}
            >
              Duplicate Column
            </DropdownItem>
            <DropdownItem
              onClick={() => {
                deleteColumn(column.id, removeColumn, toggleMoreVertDropdown);
              }}
              theme={theme}
            >
              Delete Column
            </DropdownItem>
          </DropdownMenu>
        </div>
      )}
    </>
  );
};

export default ColumnHead;
