import { getAuth, postAuth } from "../../../../global/globalUtils";

export const getTags = async (id, setTags, setViewTagsModal) => {
  try {
    const response = await getAuth(`/channel/tags/${id}`);
    const responseData = await response.json();
    setTags(responseData.tags);
    setViewTagsModal(true);
  } catch (error) {
    console.error("Failed to fetch tags:", error);
  }
};

export const fetchData = async (
  search,
  currentPage,
  itemsPerPage,
  setChannels,
  setCount,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await postAuth(
      `/channel/?page=${currentPage}&limit=${itemsPerPage}`,
      { search }
    );
    const responseData = await response.json();
    setChannels(responseData.channels);
    setCount(responseData.totalCount);
  } catch (error) {
    console.error("Failed to fetch channels:", error);
    setChannels([]);
  } finally {
    setLoading(false);
  }
};
