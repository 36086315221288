import { toast } from "sonner";
import { regenerateCode } from "../Organizations/utils";
import { deleteAuth, getAuth } from "../../../../global/globalUtils";

export const fetchData = async (
  setUsers,
  setOrgDetails,
  setCount,
  setLoading
) => {
  setLoading(true);
  try {
    const response = await getAuth(`/organization/organization-users`);
    const responseData = await response.json();
    setUsers(responseData.data);
    setCount(responseData.total);

    setOrgDetails({
      id: responseData.organization.id,
      name: responseData.organization.name,
      maxCapacity: responseData.organization.max_capacity,
      organizationCode: responseData.organization.organization_code,
      codeExpiration: responseData.organization.code_expiration,
    });
  } catch (error) {
    console.error("Failed to fetch users:", error);
    toast.error("Failed to fetch users");
    setUsers([]);
  } finally {
    setLoading(false);
  }
};

export const handleRegenerateCode = async (id, setLoading) => {
  try {
    await regenerateCode(id, setLoading);
    await fetchData();
  } catch (error) {
    toast.error("Failed to regenerate code");
    console.error("Error regenerating code:", error);
  }
};

// Function to copy the organization code to clipboard
export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  toast.success("Code copied to clipboard!");
};

export const deleteUser = async (id, users, setUsers) => {
  try {
    const response = await deleteAuth(`/admin_user/${id}`);
    await response.json();
    if (response.ok) {
      toast.success("User deleted successfully");
      const updatedUsers = users.filter((user) => user.id !== id);
      setUsers(updatedUsers);
    }
  } catch (error) {
    console.error("Failed to delete user:", error);
    toast.error("Failed to delete user");
  }
};
