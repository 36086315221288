import React, { createContext, useContext, useState } from "react";

// Create a context for the global search state
const SearchContext = createContext();

// Provider component to wrap around the part of the app that needs access to the search state
export const SearchProvider = ({ children }) => {
  const [searchState, setSearchState] = useState("");
  const [globalDate, setGlobalDate] = useState("");
  const [globalColumns, setGlobalColumns] = useState([]);

  return (
    <SearchContext.Provider
      value={{
        searchState,
        setSearchState,
        globalDate,
        setGlobalDate,
        globalColumns,
        setGlobalColumns,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

// Custom hook to use the SearchContext
export const useSearch = () => {
  const context = useContext(SearchContext);
  if (context === undefined) {
    throw new Error("useSearch must be used within a SearchProvider");
  }
  return context;
};
