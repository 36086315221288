import React, { createContext, useContext, useState } from "react";

const ClickedPostContext = createContext();

export const ClickedPostProvider = ({ children }) => {
  const [clickedPostId, setClickedPostId] = useState(null);

  return (
    <ClickedPostContext.Provider
      value={{
        clickedPostId,
        setClickedPostId,
      }}
    >
      {children}
    </ClickedPostContext.Provider>
  );
};

// Custom hook to use the ClickedPostContext
export const useClickedPost = () => {
  const context = useContext(ClickedPostContext);
  if (!context) {
    throw new Error("useClickedPost must be used within a ClickedPostProvider");
  }
  return context;
};
