import React, { useState, useCallback, useEffect, useRef } from "react";
import { debounce, set } from "lodash";
import { fetchChannelsWithTags } from "../utils/channelSearchUtil";
import { website } from "../../../global/globalUtils";
import styled from "styled-components";
import { ChosenChannelsHeader } from "./ThirdColumn";
import avatar from "../../../assets/images/default-avatar.avif";
import Spinner from "../../../global/loaders/Spinner";

const ThemedInput = styled.input.attrs({
  className: "chooseChannel",
})`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border-color: ${({ theme }) => theme.border_color};
  &:focus {
    border-color: ${({ theme }) => theme.blue};
  }
`;

const DropdownMenu = styled.div`
  background-color: ${({ theme }) => theme.popup_background};
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.border_color};
`;

const DropdownItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

const TagItem = styled.button`
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.chip_active};
  &:hover {
    background-color: ${({ theme }) => theme.delete_chip};
  }
`;

const ChannelItem = styled.div`
  &:hover {
    background-color: ${({ theme }) => theme.hover_background};
  }
`;

export const SubscriberCount = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.text};
  margin-top: 5px;
`;

const ScrollableDiv = styled.div`
  /* Webkit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.chip_inactive};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.background};
    border-radius: 10px;
    border: 3px solid ${({ theme }) => theme.chip_inactive}; /* Optional: adds space around the thumb */
  }

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.background};
  ${({ theme }) => theme.chip_inactive};
`;

const ArrowItem = styled.button`
  color: ${({ theme }) => theme.arrow_color};
`;

const AddAllButton = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: transparent;
  color: ${({ theme }) => theme.text};
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: #28a745;
  }
`;

const SecondColumn = ({
  channels,
  onAddChannel,
  chosenChannels,
  setChannels,
  deactivateRegularColumn,
}) => {
  const [tagOptions, setTagOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [channelLoading, setChannelLoading] = useState(true);
  const [query, setQuery] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const tagsContainerRef = useRef(null);
  const [page, setPage] = useState(1);

  const handleSearch = async (query, tags) => {
    setLoading(true);
    const tagIds = tags.map((tag) => tag.id);
    const chosenChannelIds = chosenChannels.map((channel) => channel.id);

    if (query.startsWith("#")) {
      // Fetch tags only
      const data = await fetchChannelsWithTags(
        query.slice(1),
        tagIds,
        [],
        page,
        setChannelLoading
      );
      if (data) {
        const newTagOptions = data.filteredTags
          .map((tag) => ({
            id: tag.id,
            name: `#${tag.name}`,
          }))
          .filter(
            (tag) =>
              !selectedTags.some((selectedTag) => selectedTag.id === tag.id)
          );
        setTagOptions(newTagOptions);
        setChannels([]);
      } else {
        setTagOptions([]);
        setChannels([]);
      }
    } else {
      // Fetch both channels and tags
      const data = await fetchChannelsWithTags(
        query,
        tagIds,
        chosenChannelIds,
        page
      );
      if (data) {
        const newTagOptions = data.filteredTags
          .map((tag) => ({
            id: tag.id,
            name: `#${tag.name}`,
          }))
          .filter(
            (tag) =>
              !selectedTags.some((selectedTag) => selectedTag.id === tag.id)
          );
        setTagOptions(newTagOptions);
        if (page === 1) {
          setChannels(data.channels);
        } else {
          setChannels((prevChannels) => [...prevChannels, ...data.channels]);
        }
      } else {
        setTagOptions([]);
        setChannels([]);
      }
    }
    setLoading(false);
    setChannelLoading(false);
  };

  const debouncedSearch = useCallback(
    debounce((query, tags) => {
      handleSearch(query, tags);
    }, 300),
    [selectedTags, chosenChannels, page]
  );

  const handleInputChange = (event) => {
    const query = event.target.value;
    setQuery(query);
    setLoading(true);
    debouncedSearch(query, selectedTags);
    setShowDropdown(true);
  };

  const handleInputFocus = () => {
    if (query && (tagOptions.length > 0 || loading)) {
      setShowDropdown(true);
    }
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setShowDropdown(false);
    }
  };

  const handleTagClick = async (tag) => {
    if (!selectedTags.find((t) => t.id === tag.id)) {
      const updatedSelectedTags = [...selectedTags, tag];
      setSelectedTags(updatedSelectedTags);
      setTagOptions((prev) => prev.filter((t) => t.id !== tag.id));
      setQuery("");
      setShowDropdown(false);
      setLoading(true);
      const data = await fetchChannelsWithTags(
        "",
        updatedSelectedTags.map((t) => t.id),
        [],
        page
      );
      if (data) {
        setChannels(data.channels);
      } else {
        setChannels([]);
      }
      setLoading(false);
    }
    updateArrowsVisibility();
  };

  const handleTagDelete = async (tag) => {
    const updatedSelectedTags = selectedTags.filter((t) => t.id !== tag.id);
    setSelectedTags(updatedSelectedTags);
    setTagOptions((prev) => [...prev, tag]);
    setLoading(true);
    const data = await fetchChannelsWithTags(
      query,
      updatedSelectedTags.map((t) => t.id),
      [],
      page
    );
    if (data) {
      setChannels(data.channels);
    } else {
      setChannels([]);
    }
    if (updatedSelectedTags.length === 0 && query === "") {
      setChannels([]);
    }
    setLoading(false);
    updateArrowsVisibility();
  };

  const scrollTags = (direction) => {
    if (tagsContainerRef.current) {
      const scrollAmount = direction === "left" ? -100 : 100;
      tagsContainerRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  const updateArrowsVisibility = () => {
    if (tagsContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = tagsContainerRef.current;
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    debouncedSearch(query, selectedTags);
  }, [query, selectedTags, debouncedSearch, page]);

  const handleChannelClick = (channel) => {
    setChannels((prevChannels) =>
      prevChannels.filter((c) => c.id !== channel.id)
    );
    onAddChannel(channel);
  };

  const scrollRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const debouncedHandleScroll = debounce(handleScroll, 200);

  const shuffleArray = (array) => {
    let shuffledArray = array.slice(); // Create a copy of the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  return (
    <div className={deactivateRegularColumn ? "" : "RegularColumn"}>
      <div className="ChooseChannelDiv">
        <ChosenChannelsHeader>
          <h3 className="ColumnHeader">Choose Channels</h3>
          <AddAllButton
            onClick={() => {
              const shuffledChannels = shuffleArray(channels);
              shuffledChannels.slice(0, 5).forEach(onAddChannel);
            }}
          >
            Add Randomly
          </AddAllButton>
        </ChosenChannelsHeader>
        <div className="chooseChannelContainer">
          <ThemedInput
            className="TextArea chooseChannel"
            type="text"
            placeholder="Search channel names or tags..."
            onChange={handleInputChange}
            onFocus={handleInputFocus}
            value={query}
            ref={inputRef}
          />
          {showDropdown && query && (
            <DropdownMenu
              ref={dropdownRef}
              className={`DropdownMenu ${
                tagOptions.length > 0 || loading ? "show" : ""
              }`}
            >
              {loading ? (
                <div className="LoadingMessage">Loading...</div>
              ) : (
                tagOptions.map((tag) => (
                  <DropdownItem
                    key={tag.id}
                    className="DropdownItem"
                    onClick={() => handleTagClick(tag)}
                  >
                    <span>{tag.name}</span>
                  </DropdownItem>
                ))
              )}
            </DropdownMenu>
          )}
        </div>
        <div className="TagsContainer">
          {showLeftArrow && (
            <i
              className="material-icons ArrowIcon left"
              onClick={() => scrollTags("left")}
            >
              arrow_back
            </i>
          )}
          <div
            className="Tags"
            ref={tagsContainerRef}
            onScroll={updateArrowsVisibility}
          >
            {selectedTags.map((tag) => (
              <TagItem
                key={tag.id}
                className="TagButton"
                onClick={() => handleTagDelete(tag)}
              >
                {tag.name}
              </TagItem>
            ))}
          </div>
          {showRightArrow && (
            <i
              className="material-icons ArrowIcon right"
              onClick={() => scrollTags("right")}
            >
              arrow_forward
            </i>
          )}
        </div>
      </div>
      <ScrollableDiv
        className="ChannelsContainer"
        ref={scrollRef}
        onScroll={debouncedHandleScroll}
      >
        {channels.map((channel) => (
          <ChannelItem
            key={channel.id}
            className="Channel"
            onClick={() => handleChannelClick(channel)}
          >
            <div className="ChannelElement">
              <div className="ChannelProfileandName">
                <div>
                  <img
                    src={channel.image ? `${website}${channel.image}` : avatar}
                    alt="Channel Icon"
                    className="ChannelIcon"
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop if fallback image also fails
                      e.target.src = avatar; // Set fallback image
                    }}
                  />
                </div>
                <div className="ChannelName">
                  <span className="channel_name">{channel.chat_title}</span>
                  <br />
                  <span className="channel_username">
                    @{channel.sender_username}
                  </span>
                  <SubscriberCount>
                    {channel.subscriber_count !== null &&
                    channel.subscriber_count !== undefined &&
                    channel.subscriber_count !== 0
                      ? `${channel.subscriber_count.toLocaleString()} subscribers`
                      : ""}
                  </SubscriberCount>
                </div>
              </div>
              <ArrowItem className="ArrowRightButton">
                <i className="material-icons">arrow_right</i>
              </ArrowItem>
            </div>
          </ChannelItem>
        ))}
        {channelLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Spinner />
          </div>
        )}
      </ScrollableDiv>
    </div>
  );
};

export default SecondColumn;
