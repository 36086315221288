import React from "react";
import styled from "styled-components";

const ImageContainer = styled.div`
  width: 250px;
  height: 150px;
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
  margin-left: 50%;
  transform: translateX(-50%);
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00000060;
  display: flex;
  align-items: center;
  border-radius: 10px;
  box-sizing: border-box;
  justify-content: center;
`;

const Icon = styled.i`
  font-size: 56px;
  color: ${({ theme }) => theme.body};
  border: 2px solid ${({ theme }) => theme.body};
  border-radius: 36px;
  background-color: ${({ theme }) => theme.play_background};
`;

export const DEFAULT_IMAGE = "https://data.teledeck.news/default.png";

function SingleImage({ media, alt }) {
  const handleImageError = (event) => {
    event.target.src = DEFAULT_IMAGE;
  };

  if (media["image"])
    return (
      <ImageContainer>
        <Image src={media["image"]} alt={alt} onError={handleImageError} />
      </ImageContainer>
    );

  return (
    <ImageContainer>
      <Overlay>
        <Icon className="material-icons">play_arrow</Icon>
      </Overlay>
      <Image src={media["video"]} alt={alt} onError={handleImageError} />
    </ImageContainer>
  );
}

export default SingleImage;
