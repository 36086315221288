import React from "react";
import styled from "styled-components";
import { useTheme } from "../../themes/ThemeContext";
import darkMode from "../../../assets/images/dark-mode.png";
import lightMode from "../../../assets/images/light-mode.png";

export const Header = styled.h3`
  margin-top: 0;
`;

export const Prompt = styled.p`
  margin-top: 0;
  margin-bottom: 20px; // Good spacing before the toggle buttons
  padding: 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px; // Adequate space between theme selections
`;

const ToggleButton = styled.button`
  width: 100px;
  height: 100px;
  border-radius: 8px;
  border: 3px solid ${({ active }) => (active ? "#4CAF50" : "transparent")}; // Highlight the active theme
  background-image: url(${({ background }) => background});
  background-size: contain;
  background-position: center;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  transition: border-color 0.3s ease;
`;

const Label = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.text};
  margin-top: 5px; // Ensure label is spaced properly from the image
`;

const Appearance = () => {
  const { theme, toggleTheme } = useTheme();

  const handleChange = (newTheme) => {
    toggleTheme(newTheme);
  };

  return (
    <>
      <Header>Theme</Header>
      <Prompt>Select the theme you would like to use:</Prompt>
      <ToggleContainer>
        <div>
          <ToggleButton
            active={theme === "light"}
            onClick={() => handleChange("light")}
            background={lightMode}
          />
          <Label>Light</Label>
        </div>
        <div>
          <ToggleButton
            active={theme === "dark"}
            onClick={() => handleChange("dark")}
            background={darkMode}
          />
          <Label>Dark</Label>
        </div>
      </ToggleContainer>
    </>
  );
};

export default Appearance;
