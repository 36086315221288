import React from "react";
import styled from "styled-components";
import { ContentRow } from "./components/ContentRow";
import { useColumns } from "../../column/ColumnsContext";

const ResetButton = styled.button`
  background: #f44336;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background 0.3s;

  &:hover {
    background: #d32f2f;
  }
`;

const General = () => {
  const { resetColumnProperties } = useColumns();

  const handleReset = async () => {
    // Delete all local storage variables that match the format inputValue
    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith("inputValue-")) {
        localStorage.removeItem(key);
      }
    });

    await resetColumnProperties();

    // Refresh the page
    window.location.reload();
  };

  return (
    <>
      <ContentRow title="Reset All Column Filters">
        <ResetButton title="This will refresh the page" onClick={handleReset}>
          Reset
        </ResetButton>
      </ContentRow>
    </>
  );
};

export default General;
